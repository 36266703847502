import { AuthService } from './AuthService';
import { ResponseProcessor } from './ResponseProcessor';
import { API_URL } from '../config';
import { RedirectPolicy } from '../domain/redirects/RedirectPolicy';
import { RedirectLink } from '../domain/redirects/RedirectLink';

export class RedirectPolicyService {

    private _baseUrl: string;
    private _authService: AuthService;
    private _responseProcessor: ResponseProcessor;

    constructor() {
        this._baseUrl = `${API_URL}/redirects`;
        this._authService = new AuthService();
        this._responseProcessor = new ResponseProcessor(this._authService);
    }

    fetch = async (): Promise<RedirectPolicy[]> => {
        const headers = this._authService.getAuthHeader();
        const response = await fetch(this._baseUrl, {
            headers: headers
        });
        return this._responseProcessor.processResponse(response);
    }

    async createRedirect(redirect: RedirectPolicy) {
        const headers = this._authService.getAuthHeader();

        const requestBody = this.transformRedirect(redirect);
        const json = JSON.stringify(requestBody);

        const resp =
            await fetch(this._baseUrl, {
                method: 'POST',
                headers: headers,
                body: json
            });
        return await this._responseProcessor.processResponse(resp);
    }

    async updateRedirect(redirect: RedirectPolicy): Promise<RedirectPolicy> {
        const headers = this._authService.getAuthHeader();

        const requestBody = this.transformRedirect(redirect);
        const json = JSON.stringify(requestBody);

        const resp =
            await fetch(this._baseUrl, {
                method: 'PUT',
                headers: headers,
                body: json
            });
        return await this._responseProcessor.processResponse(resp);
    }

    async removeRedirect(redirectId: number) {
        const headers = this._authService.getAuthHeader();
        const resp =
            await fetch(`${this._baseUrl}/${redirectId}`, {
                method: 'DELETE',
                headers: headers,
            });
        return await this._responseProcessor.processResponse(resp);
    }

    private transformRedirect(redirect: RedirectPolicy) {
        //eslint-disable-next-line
        const clone = structuredClone(redirect);
        clone.links.forEach((link: RedirectLink) => {
            if (link.id < 0)
                link.id = 0;
        });

        return clone;
    }
}