import {AuthService} from './AuthService';
import {ResponseProcessor} from './ResponseProcessor';

export class FeedbackService {
    constructor() {
        this.baseUrl = "/api/feedback";
        this._authService = new AuthService();
        this._responseProcessor = new ResponseProcessor(this._authService);
    }

    sendFeedback = async (request) => {
        const data = JSON.stringify({Email: request.email, Subject: request.subject, Message: request.message});
        const url = `${this.baseUrl}`;
        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: data
        });
        return await this._responseProcessor.processResponse(resp);
    }
}