import React from 'react';
import './SocialLinks.css';

import spriteImage from '../../../images/sprite.svg';

const SocialLinks = () => {
    return (
        <ul className="social-link-container social-link-share-container">
            {/* <li>
                <a className="social-link social-link-fb"
                    href={`https://www.facebook.com/sharer.php?u=${document.location.href}`}
                    id="fbShareLink"
                    title="Поделиться в facebook" aria-label="facebook">
                    <svg viewBox="0 0 15 35" >
                        <use xlinkHref={`${spriteImage}#fb`} />
                    </svg>
                    <span className="socail-link__screen-readers">Поделиться в facebook</span>
                </a>
            </li> */}
            <li>
                <a className="social-link social-link-vk"
                   href={`http://vk.com/share.php?url=${document.location.href}`}
                   id="vkShareLink"
                   title="Поделиться в vk" aria-label="vk">
                    <svg viewBox="0 0 32 22">
                        <use xlinkHref={`${spriteImage}#vk`}/>
                    </svg>
                    <span className="socail-link__screen-readers">Поделиться в vk</span>
                </a>
            </li>
        </ul>
    );
}

export {SocialLinks};