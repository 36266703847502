import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { ChannelModal } from "./Channels/ChannelModal";
import { ErrorNotificator } from './ErrorNotificator/ErrorNotificator';

export const Layout = (props) => (
    <React.Fragment>
        <Header />
        <main className="content-block main">
            <div className="container">
                {props.children}
            </div>
        </main>
        <Footer />
        <ChannelModal />
        <ErrorNotificator />
    </React.Fragment>
);
