import {AuthService} from './AuthService';
import {ResponseProcessor} from './ResponseProcessor';

export class FavoritesService {
    constructor() {
        this.baseUrl = "/api/favorites";
        this._authService = new AuthService();
        this._responseProcessor = new ResponseProcessor(this._authService);
    }

    getFavoriteNews = async () => {
        let headers = this._authService.getAuthHeader();
        const resp =
            await fetch(this.baseUrl,
                {headers: headers});
        return await this._responseProcessor.processResponse(resp);
    }

    getFavoriteIds = async () => {
        let headers = this._authService.getAuthHeader();
        const url = `${this.baseUrl}/ids`;
        const resp = await fetch(url,
            {headers: headers});
        return await this._responseProcessor.processResponse(resp);
    }

    addFavorites = async (id) => {
        let headers = this._authService.getAuthHeader();
        const data = JSON.stringify({NewsId: id});
        const url = `${this.baseUrl}`;
        const resp = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await this._responseProcessor.processResponse(resp);
    }

    removeFromFavorites = async (id) => {
        let headers = this._authService.getAuthHeader();
        const data = JSON.stringify({NewsId: id});
        const url = `${this.baseUrl}`;
        const resp = await fetch(url, {
            method: 'DELETE',
            headers: headers,
            body: data
        });
        return await this._responseProcessor.processResponse(resp);
    }
}