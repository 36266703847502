import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../Loader';
import { ProfileChangeEmail } from './ProfileChangeEmail';
import { ProfileSocial } from './ProfileSocial';
import { ProfileChangePassword } from './ProfileChangePassword';
import { fetchUserProfile } from '../../actions/Profile/ProfileActions';
import { withDefaultNoFollowMeta } from "../../hoc";

import "./Profile.css";

const ProfileImpl = () => {

    const dispatch = useDispatch();
    const loading = useSelector(state => state.userProfile.loading);
    const profile = useSelector(state => state.userProfile.profile);

    useEffect(() => {
        dispatch(fetchUserProfile());
        document.querySelector('meta[name="robots"]')?.setAttribute("content", "noindex, nofollow");
    }, []);


    if (loading || !profile) {
        return <Loader visible={loading} />;
    }

    return (
        <div className="profile">
            <h1 className="profile__title">Мой профиль</h1>

            <div className="profile__details">
                <ProfileChangeEmail email={profile.email} />
                <ProfileChangePassword />
                {/* <ProfileSocial name="Facebook" value={profile.facebookPage} baseUrl="https://www.facebook.com" /> */}
                <ProfileSocial name="Vkontakte" value={profile.vkPage} baseUrl="https://vk.com" />

            </div>
        </div>
    );
}

export const Profile = withDefaultNoFollowMeta(ProfileImpl);