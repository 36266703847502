import {AuthService} from './AuthService';
import {ResponseProcessor} from './ResponseProcessor';

export class ProfileService {
    constructor() {
        this.baseUrl = "/api/profile";
        this._authService = new AuthService();
        this._responseProcessor = new ResponseProcessor(this._authService);
    }

    getUserProfile = async () => {
        if (!this._authService.isAuthenticated())
            window.location = '/login';

        let headers = this._authService.getAuthHeader();
        const resp =
            await fetch(this.baseUrl,
                {headers: headers});
        return await this._responseProcessor.processResponse(resp);
    }

    changeSocialPage = async (socialName, pageAddress) => {
        let headers = this._authService.getAuthHeader();
        const data = JSON.stringify({SocialName: socialName, PageAddress: pageAddress});
        const url = `${this.baseUrl}/social`;
        const resp = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await this._responseProcessor.processResponse(resp);
    }
}