import React, { Component, Fragment } from 'react';
import { NewsList } from '../NewsList';
import { ChannelSlider } from '../ChannelSlider';
import { Loader } from '../Loader';
import { NewsItemModal } from '../NewsItemModal';
import { NewsPerPage } from '../../config';
import { RegistrationBanner } from '../RegistrationBanner';

import "./NewsPage.css";

export class NewsPage extends Component {
    renderSlider = () => {
        if (this.props.authService.isAuthenticated())
            return <ChannelSlider />;

        return <RegistrationBanner />;
    }

    renderButtonMore() {
        const { newsList, loading, pageNumber } = this.props;

        if (loading)
            return null;

        return (<button
            className="button-more"
            onClick={this.props.onNextPage}
            disabled={newsList.length / pageNumber < NewsPerPage}
        >Загрузить еще</button>);
    }

    render() {
        if (this.props.newsList.length === 0 && !this.props.loading) {
            return <Fragment>
                {this.renderSlider()}
                <div className="nothing-found-caption">
                    Ничего не найдено
                </div>

            </Fragment>
        }

        return (
            <React.Fragment>
                {this.renderSlider()}
                <div className={`news-container ${this.props.viewMode}`}>
                    <NewsList newsList={this.props.newsList} isLoading={this.props.loading}
                        openModal={this.props.openModal} />
                    <NewsItemModal
                        index={this.props.selectedNewsItemIndex}
                        showModal={this.props.show} />
                    <Loader visible={this.props.loading} />
                </div>
                <div className="button-container">
                    {this.renderButtonMore()}
                </div>
            </React.Fragment>
        );
    }
}