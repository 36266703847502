import { RedirectPolicy } from "../../domain/redirects/RedirectPolicy";
import * as actionTypes from "../types";
import { TRedirectAction } from "./redirect-action-types";

export const redirectFetch = (): TRedirectAction => {
    return {
        type: actionTypes.RedirectActionTypes.FETCH_REDIRECTS,
        payload: undefined
    }
}

export const redirectFetchSuccess = (redirects: RedirectPolicy[]): TRedirectAction => {
    return {
        type: actionTypes.RedirectActionTypes.FETCH_REDIRECTS_SUCCESS,
        payload: redirects
    }
}

export const redirectFetchFailed = (error: any): TRedirectAction => {
    return {
        type: actionTypes.RedirectActionTypes.FETCH_REDIRECTS_FAILED,
        payload: error
    }
}


export const redirectSave = (): TRedirectAction => {
    return {
        type: actionTypes.RedirectActionTypes.SAVE_REDIRECT,
        payload: undefined
    }
}

export const redirectSaveSuccess = (): TRedirectAction => {
    return {
        type: actionTypes.RedirectActionTypes.SAVE_REDIRECT_SUCCESS,
        payload: undefined
    }
}

export const redirectSaveFailed = (error: any): TRedirectAction => {
    return {
        type: actionTypes.RedirectActionTypes.SAVE_REDIRECT_FAILED,
        payload: error
    }
}