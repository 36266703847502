import React, {Component} from 'react';
import {connect} from 'react-redux';
import VkLogin from './vk-button';
import {VkAppId} from '../../../config';
import {externalSigninUser} from '../../../actions/Auth/Auth';

export class VkImpl extends Component {
    onResponse = (response) => {
        if (response.session) {
            const userId = response.session.user.id;
            const email = `id${userId}@digezt.ru`;
            this.props.externalSigninUser(email, userId, "Vkontakte");
        }
    }

    render() {
        return (
            <VkLogin
                appId={VkAppId}
                apiId={VkAppId}
                autoLoad={false}
                fields="name,email"
                textButton="VK"
                onClick={this.onResponse}
                callback={this.onResponse}
                disabled={false}/>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        externalSigninUser: (email, token, provider) => {
            dispatch(externalSigninUser(email, token, provider));
        }
    }
};

export const Vk = connect(mapStateToProps, mapDispatchToProps)(VkImpl);