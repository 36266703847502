import React, { Component } from "react";
import Modal from 'react-modal';
import moment from "moment";
import { GenericModal } from '../GenericModal';
import { connect } from 'react-redux';
import { NewsItemModalControls } from './NewsItemModalControls';
import { Link, withRouter } from 'react-router-dom';
import { MaxDescriptionLength } from '../../config';
import { TextTrimService } from '../../services/TextTrimService';

import { newsItemSelected, newsModalHide } from '../../actions/News/NewsActions';

import "./NewsItemModal.css";

Modal.setAppElement('#root');
Modal.defaultStyles = {};

class NewsItemModalImpl extends Component {

    constructor(props) {
        super(props);
        this.textTrimService = new TextTrimService();
        this.state = {
            trimmed: true,
            originalAddress: undefined
        };
    }

    componentDidMount() {
        this.setState({ modalIsOpen: this.props.showModal });
        document.addEventListener("keyup", this.handleKeyUp, true);
        this.changeMetas(this.props.newsList[this.props.selectedNewsItemIndex]);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedNewsItemIndex !== this.props.selectedNewsItemIndex) {
            this.changeMetas(this.props.newsList[this.props.selectedNewsItemIndex]);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.handleKeyUp);
    }

    changeMetas = (item) => {
        if (!item) {
            return;
        }

        document.title = item.title;
        document.querySelector('meta[name="robots"]')?.setAttribute("content", "nofollow");
        document.querySelector('meta[property="og:title"]')?.setAttribute("content", item.title);
        document.querySelector('meta[name="description"]')?.setAttribute("content", item.description);
        document.querySelector('meta[property="og:description"]')?.setAttribute("content", item.description);

        document.querySelector('meta[property="og:url"]')?.setAttribute("content", window.location);
        if (item.imageUrl) {
            document.querySelector('meta[property="og:image"]')?.setAttribute("content", item.imageUrl);
            document.querySelector('meta[property="og:image:width"]')?.setAttribute("content", "368");
            document.querySelector('meta[property="og:image:height"]')?.setAttribute("content", "368");
        }
    }

    handleKeyUp = (e) => {
        const { showModal, selectedNewsItemIndex, newsList } = this.props;
        if (!showModal) {
            return;
        }
        if (e.keyCode !== 37 && e.keyCode !== 39) {
            return;
        }

        const step = e.keyCode === 37 ? -1 : 1;
        if (step === -1 && selectedNewsItemIndex === 0 ||
            step === 1 && selectedNewsItemIndex === newsList.length - 1) {
            return;
        }
        this.onChangeNews(step);
    }

    onOpenModal = () => {
        const originalAddress = window.location.pathname;

        if (!originalAddress.startsWith("/item"))
            this.setState({ originalAddress: originalAddress });

        const { id, slug } = this.props.newsList[this.props.selectedNewsItemIndex];

        const itemAddress = `/item/${id}-${slug}`;
        if (window.location.pathname !== itemAddress)
            window.history.pushState({}, {}, itemAddress);
    }

    onCloseModal = () => {
        this.props.closeModal();
        if (window.location.pathname !== this.state.originalAddress)
            window.history.pushState({}, {}, this.state.originalAddress);

        document.querySelector('meta[property="og:title"]')?.setAttribute("content", "Digezt: дайджест ваших интересов");
        document.querySelector('meta[name="description"]')?.setAttribute("content", "Дайджест-портал, позволяющий ежедневно получать подборку самых интересных мировых новостей, событий и предложений");
        document.querySelector('meta[property="og:description"]')?.setAttribute("content", "Дайджест-портал, позволяющий ежедневно получать подборку самых интересных мировых новостей, событий и предложений");
        document.title = "Digezt";
        document.querySelector('meta[property="og:url"]')?.setAttribute("content", "http://digezt.ru/");
        document.querySelector('meta[property="og:image"]')?.setAttribute("content", "")
        document.querySelector('meta[property="og:image:width"]')?.setAttribute("content", "");
        document.querySelector('meta[property="og:image:height"]')?.setAttribute("content", "");
    }

    renderImage(news) {
        if (!news.imageUrl)
            return null;

        return (<img className="news-modal__image" src={news.imageUrl} alt={news.title} />)
    }

    onChangeNews = (step) => {
        this.props.newsItemSelected(this.props.selectedNewsItemIndex + step);
        const { id, slug } = this.props.newsList[this.props.selectedNewsItemIndex + step];
        const itemAddress = `/item/${id}-${slug}`;
        window.history.pushState({}, {}, itemAddress);
        this.setState({ trimmed: true });
    }

    getDescription = (description) => {
        if (!description)
            return '';

        if (!this.state.trimmed)
            return description;

        return this.textTrimService.trimWithEllipsis(description, MaxDescriptionLength);
    }

    onReadMore = () => {
        if (this.state.trimmed)
            this.setState({ trimmed: false });
    }

    onChannelLinkClick = (news) => {
        const self = this;
        this.setState({ originalAddress: `/channels/${news.channelId}-${news.channelName}` }, () => {
            self.onCloseModal();
        })
    }

    render() {
        const news = this.props.newsList[this.props.selectedNewsItemIndex];
        if (!news)
            return null;

        const formattedDate = moment(news.publishDate).format("DD.MM.YYYY HH:mm:ss");
        return (
            <GenericModal className="news-modal"
                isOpen={this.props.showModal}
                onOpen={this.onOpenModal}
                onClose={this.onCloseModal}
                showArrow={true}
                shouldCloseOnOverlayClick={true}
                firstItem={this.props.selectedNewsItemIndex === 0}
                lastItem={this.props.selectedNewsItemIndex === this.props.newsList.length - 1}
                onArrowClick={this.onChangeNews}>

                <div className="news-modal__modal-body">
                    <div className="news-modal__news-props">
                        <time className="news-modal__date">{formattedDate}</time>
                        <Link
                            className="news-modal__channel"
                            onClick={() => this.onChannelLinkClick(news)}
                            to={`/channels/${news.channelId}-${news.channelName}`}>
                            {news.channelName}</Link>
                    </div>
                    <h1 className="news-modal__title" ref={subtitle => this.subtitle = subtitle}>{news.title}</h1>
                    <div className="news-modal__image-container">
                        {this.renderImage(news)}
                    </div>
                    <p className="news-modal__description">
                        {this.getDescription(news.description)}
                    </p>
                    {this.state.trimmed && news.description && news.description.length > MaxDescriptionLength &&
                        < button onClick={this.onReadMore} className="news-modal__read-more">
                            Читать дальше
                        </button>
                    }

                    <NewsItemModalControls news={news} path={this.props.match.path} />
                </div>
            </GenericModal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newsList: state.news.newsList,
        pageNumber: state.news.pageNumber,
        selectedChannelId: state.news.selectedChannelId,
        loading: state.news.loading,
        selectedNewsItemIndex: state.news.selectedNewsItemIndex
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        newsItemSelected: (index) => {
            dispatch(newsItemSelected(index));
        },
        closeModal() {
            dispatch(newsModalHide())
        }
    }
};

export const NewsItemModal = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsItemModalImpl));