import React from "react";
import { Link } from 'react-router-dom';
import { withDefaultNoFollowMeta } from "../../hoc";

import "./Policy.css";


const PolicyImpl = () => {
    return (
        <div className="privacy-policy-container">
            <h1 className="policy-header">Пользовательское соглашение </h1>

            <p className="policy-usage">Использование веб-сайта <Link to="/"> Digezt.ru </Link> и доступ к нему
                определяется настоящими Правилами.</p>


            <ol className="numbered-list numbered-list__top">

                <li>
                    <h2>Общие положения</h2>

                    <p className="policy-section">
                        Веб-сайт Digezt.ru является информационным ресурсом. Заходя на сайт, пользователь принимает
                        настоящие Правила. Digezt.ru оставляет за собой право изменять настоящие Правила и обязуется
                        отражать любые изменения в настоящем документе. Пользователи самостоятельно отслеживают
                        данные изменения и принимают решение о возможности дальнейшего использования сайта.
                    </p>
                </li>
                <li>
                    <h2> Использование материалов сайта</h2>
                    <p className="policy-section">
                        Информация, указанная на данном сайте представляется как публичная информация (если иное
                        не указано в материалах) и может свободно распространяться, или копироваться для
                        некоммерческого использования в информационных, научных, учебных или культурных целях.
                    </p>

                    <p className="policy-section">
                        Все права на публикации принадлежат их авторам и первоисточникам. Новостная информация,
                        включающая текстовые сообщения и материалы к ним, предоставлена российскими и
                        зарубежными СМИ и агрегирована в автоматическом режиме по заранее определенным
                        алгоритмам. У каждой новости указаны ссылки на Источники, от которых эта информация
                        получена. В соответствии с законодательством РФ материалы, нарушающие интеллектуальные
                        права, можно удалить по запросу
                    </p>

                    <p className="policy-section">
                        Размещенные в сервисе материалы могут содержать информацию, предназначенную для
                        пользователей старше 18 лет. 18+
                    </p>
                </li>
                <li>
                    <h2>Использование сайта</h2>
                    <p className="policy-section">
                        Использование сайта должно осуществляться исключительно в рамках действующего
                        законодательства Российской Федерации и не должно нарушать или ограничивать права третьих
                        лиц. Запрещаются любые действия, связанные с передачей материалов непристойного
                        содержания или ведущие к сбою функционирования сайта.
                    </p>
                </li>
                <li>
                    <h2>Использование персональных данных</h2>
                    <p className="policy-section">
                        В рамках использования посетителем сайта могут быть получены:
                    </p>
                    <ol className="numbered-list numbered-list__nested">
                        <li>
                            данные в автоматическом режиме (информацию об используемом устройстве, IP-адрес,
                            данные, сохраненные в файлах cookies, user-agent, дата и время доступа к сайту, адреса
                            запрашиваемых страниц)
                        </li>
                        <li>
                            данные, предоставленные пользователем самостоятельно (электронная почта, ссылки на
                            персональный сайт или социальную сеть)
                        </li>
                    </ol>
                    <p className="policy-section">
                        Персональные данные посетителя могут быть использованы в следующих целях:
                    </p>
                    <ol className="numbered-list numbered-list__nested">
                        <li>
                            Идентификация в рамках обращений со стороны посетителя. Хранение и обработка
                            запросов и переданной информации. Осуществление обрабной связи с пользователями в
                            рамках их обращений и запросов.
                        </li>
                        <li>
                            Предоставление посетителю персонализированных сервисов, услуг, предложений
                        </li>
                        <li>
                            Использование в системах статистики для мониторинга, анализа и улучшение работы сайта
                        </li>
                        <li>
                            Таргетирование рекламных материалов
                        </li>
                        <li>Проведение статистических и иных исследований</li>
                        <li>Передача третьим лицам в рамках деятельности сайта, либо в случаях прямо
                            предусмотренных законодательством.
                        </li>
                    </ol>
                    <p className="policy-section">
                        Обработка персональных данных происходит в соответствии с Фдеральным законом от 27.07.2006
                        N 152-ФЗ «О персональных данных» и иными нормативно-правовыми актами Российской
                        Федерации.
                    </p>
                </li>
                <li>
                    <h2>Сайт предназначен для лиц старше 18 лет.</h2>
                </li>
                <li>
                    <h2> Согласие с Правилами</h2>
                    <p className="policy-section">
                        Настоящие Правила вступают в силу в момент посещеия сайта. Если вы не принимаете настоящие
                        Правила в их целостности, просьба сразу же покинуть сайт.
                    </p>
                </li>
            </ol>
        </div>
    );
}

export const Policy = withDefaultNoFollowMeta(PolicyImpl);