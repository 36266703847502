import { Reducer } from 'react';
import { TFeedbackAction } from '../actions/Feedback/feedback-action-types';
import * as actionTypes from '../actions/types';
import { BaseReducer } from "./BaseReducer";

interface IFeedbackState {
    wasSend: boolean,
    inProcess: boolean
}

export class InitialState implements IFeedbackState {
    wasSend: boolean = false;
    inProcess: boolean = false;
}

const handlers = {
    [actionTypes.FeedbackActionTypes.FEEDBACK_SEND]: feedbackSend,
    [actionTypes.FeedbackActionTypes.FEEDBACK_SEND_SUCCESS]: feedbackSendSuccess,
    [actionTypes.FeedbackActionTypes.FEEDBACK_SEND_FAILED]: feedbackSendFailed,
    [actionTypes.FeedbackActionTypes.FEEDBACK_SEND_APPROVE]: feedbackSendApprove,
};

function feedbackSend(state: IFeedbackState) {
    return {
        ...state,
        inProcess: true,
        wasSend: false
    };
}

function feedbackSendSuccess(state: IFeedbackState) {
    return {
        ...state,
        inProcess: false,
        wasSend: true
    };
}

function feedbackSendFailed(state: IFeedbackState) {
    return {
        ...state,
        inProcess: false,
        wasSend: true
    };
}

function feedbackSendApprove(state: IFeedbackState) {
    return {
        ...state,
        inProcess: false,
        wasSend: false
    };
}

export const feedbackReducer: Reducer<IFeedbackState, TFeedbackAction> = (state: IFeedbackState | undefined = new InitialState(), action: TFeedbackAction) => {
    const baseReducer = new BaseReducer(handlers);
    const handler: any = baseReducer.getReducer(action.type)
    return handler ? handler(state, action) : state;
}

