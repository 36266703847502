import * as actionTypes from '../types';

const viewTypeChanged = (viewMode) => {
    return {
        type: actionTypes.VIEW_SELECTOR_MODE_CHANGED,
        payload: viewMode,

    };
};

export {viewTypeChanged};