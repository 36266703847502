import * as actionTypes from '../actions/types';

const initialState = {
    channels: [],
    loading: true,
    validating: false,
};

const channelReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CHANNEL_LOADED:
            return {
                ...initialState,
                channels: action.payload,
                loading: false
            };

        default:
            return state;
    }
}

export { channelReducer };
