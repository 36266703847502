import { useSelector } from 'react-redux';
import { DigestRoomButton } from './DigestRoomButton';
import { SearchPanel } from './SearchPanel';
import { Subscription } from './Subscription';
import { LoginPanel } from './LoginPanel';
import { AuthService } from '../../services/AuthService';
import "./Header.css";

export const Header = () => {
    const authService = new AuthService();
    const authenticated = useSelector((state) => state.auth.authenticated);

    const renderAuthUserElements = () => {
        if (authService.isAuthenticated() || authenticated) {
            return (<Subscription />
            );
        } else {
            return (<div className="anonym-shim"></div>)
        }
    }

    return (
        <header className="content-block page-header header">
            <DigestRoomButton />
            <LoginPanel />
            <SearchPanel />
            {renderAuthUserElements()}
        </header>
    );
}