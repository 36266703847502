import React, { Component } from "react";
import { TextTrimService } from "../../services/TextTrimService";
import { TilesService } from "../../services/TilesService";
import { NewsItemMaxDescriptionLength } from '../../config';
import { getBlankImageStyle } from "./palette";

import "./NewsItem.css";

export class NewsItem extends Component {
    textTrimService = new TextTrimService();
    tilesService = new TilesService();
    maxDescriptionLength = NewsItemMaxDescriptionLength;

    onNewsItemClicked = (event, index) => {
        event.preventDefault();
        this.props.onNewsItemClicked(index);
        return false;
    };

    getBackgroundStyle = (newsItem) => {
        if (newsItem.imageUrl) {
            return { backgroundImage: `url(${encodeURI(newsItem.imageUrl)})` };
        }

        const backgroundStyle = getBlankImageStyle(newsItem.id);
        return backgroundStyle;
    }

    render() {
        const newsItem = this.props.news;
        const imageBackground = this.getBackgroundStyle(newsItem);

        const itemUrl = `/news/${newsItem.id}-${newsItem.slug}`;
        return (
            <article
                className={this.tilesService.getNewsItemClassList(
                    newsItem,
                    this.props.index
                )}
                style={{ ...imageBackground }}
            >
                <a
                    className="news-item__link"
                    href={itemUrl}
                    onClick={event => this.onNewsItemClicked(event, this.props.index)}
                >
                    <div className="news-item__info">

                    </div>
                    <div className="news-item__block">
                        <p className="news-item__title">{newsItem.title}</p>
                        <p className="news-item__description">
                            {this.textTrimService.trimWithEllipsis(
                                newsItem.description,
                                this.maxDescriptionLength
                            )}
                        </p>
                    </div>
                </a>
            </article>
        );
    }
}
