export class ResponseProcessor {
    constructor(authService) {
        this._authService = authService;
    }

    processResponse = async (response, isLoginResponse) => {
        if (!isLoginResponse)
            this._authService.checkUnautorizeResult(response.status);

        if (response.ok) {
            if (response.status === 204)
                return {};

            const body = await response.json();
            return body;
        } else {
            let message = '';
            switch (response.status) {
                case 500:
                    message = "Произошла ошибка на сервере";
                    break;
                case 401:
                    message = await this.getResponseError(response) ?? "Не правильный адрес электронной почты или пароль";
                    break;
                case 400:
                    message = await this.getResponseError(response) ?? "Произошла ошибка во время выполнения запроса";
                    break;
                case 404:
                    message = await this.getResponseError(response) ?? "Произошла ошибка во время выполнения запроса";
                    break;
                default:
                    message = response.statusText;
            }

            throw new Error(message);
        }
    }

    getResponseError = async (response) => {
        const contentType = response.headers.get("content-type");
        if (contentType.startsWith("application/json") || contentType.startsWith("application/problem+json")) {
            const body = await response.json();
            if (body.errors) {
                let message = body.title;
                Object.entries(body.errors).forEach((entry) => {
                    message += ' ' + entry && entry.length > 1 ? entry[1] : "";
                })
                return message;
            }

            return body.title;
        }
        return await response.text();
    }
}