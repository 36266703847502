import React from 'react';

class vkLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSdkLoaded: false,
            isProcessing: false,
        };
        this.checkLoginState = this.checkLoginState.bind(this);
        this.click = this.click.bind(this);
    }

    componentDidMount() {
        if (document.getElementById('vk-jssdk')) {
            this.sdkLoaded();
            return;
        }
        this.setFbAsyncInit();
        this.loadSdkAsynchronously();
    }

    setFbAsyncInit() {
        const { apiId } = this.props;
        window.vkAsyncInit = () => {
            window.VK.init({ apiId });
            this.setState({ isSdkLoaded: true });
        };
    }

    sdkLoaded() {
        this.setState({ isSdkLoaded: true });
    }

    loadSdkAsynchronously() {
        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.src = 'https://vk.com/js/api/openapi.js?162';
        el.async = true;
        el.id = 'vk-jssdk';
        document.getElementsByTagName('head')[0].appendChild(el);
    }

    checkLoginState = (response) => {
        this.setState({ isProcessing: false });

        if (this.props.callback) {
            this.props.callback(response);
        }
    };

    click = () => {
        if (!this.state.isSdkLoaded || this.state.isProcessing || this.props.disabled) {
            return;
        }
        this.setState({ isProcessing: true });
        window.VK.Auth.login((response) => {
            this.props.callback(response);
        }, this.checkLoginState, 1 << 22);
    };

    // style() {
    //     return <style dangerouslySetInnerHTML={{__html: {}}}/>;
    // }

    // // [AdGo] 20.11.2016 - coult not get container class to work
    // containerStyle() {
    //     const style = {transition: 'opacity 0.5s'};
    //     if (this.state.isProcessing || !this.state.isSdkLoaded || this.props.disabled) {
    //         style.opacity = 0.6;
    //     }
    //     return Object.assign(style, this.props.containerStyle);
    // }

    render() {

        return (
            <button
                className="social-logins__link social-logins__link_vk"
                onClick={(e) => this.click(e)}
            >VK</button>
        );
    }
}

export default vkLogin;
