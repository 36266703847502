import * as actionTypes from '../actions/types';

const initialState = {
    channels: [],
    selectedChannelId: -1,
    loading: true,
    error: null,
};

const channelSliderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SLIDER_CHANNEL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SLIDER_CHANNEL_FETCHED:
            return {
                ...state,
                channels: action.payload,
                loading: false,
                error: null
            };
        case actionTypes.SLIDER_CHANNEL_SELECTED:
            return {
                ...state,
                selectedChannelId: action.payload,
                loading: false
            };
        case actionTypes.SLIDER_CHANNEL_FAILTURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}

export {channelSliderReducer};
