import React from "react";
import { withDefaultNoFollowMeta } from "../../hoc";

import "./About.css";

const AboutImpl = () => {
    return (
        <div className="about-info">
            <h1 className="policy-header">О проекте </h1>

            <p className="policy-section">
                Digezt — подборка интересных новостей, событий и предложений.
            </p>

            <p className="policy-section">
                Интуитивно понятный интерфейс и никаких рекомендательных алгоритмов.
            </p>

            <p className="policy-section">
                Выбор за пользователем.
            </p>
        </div>
    );
}

export const About = withDefaultNoFollowMeta(AboutImpl);