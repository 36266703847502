import React, { Component } from 'react';
import Masonry from 'react-masonry-component';
import { NewsItem } from '../NewsItem';

import './NewsList.css';

export class NewsList extends Component {

    masonryOptions = {
        percentPosition: true,
        transitionDuration: 500,
        columnWidth: '.news-item',
        updateOnEachImageLoad: true,
        gutter: 20
    };

    constructor(props) {
        super(props);
        this.onNewsItemSelected = this.onNewsItemSelected.bind(this);
    }

    componentDidMount() {
        document.querySelector('meta[name="robots"]')?.setAttribute("content", "nofollow");
    }

    onNewsItemSelected = (selectedNewsItem) => {
        this.props.openModal(selectedNewsItem);
    }

    renderItem(news) {
        return (news.map((item, index) => {
            return (<NewsItem key={item.id} news={item} index={index} onNewsItemClicked={this.onNewsItemSelected} />);
        }));
    }

    render() {
        const { newsList } = this.props;
        const items = this.renderItem(newsList);

        return (
            <Masonry
                className={'news-block'}
                elementType={'div'}
                options={this.masonryOptions}
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}>
                {items}
            </Masonry>
        );
    }
}