import {AuthService} from './AuthService';
import {ResponseProcessor} from './ResponseProcessor';

export class NewsService {
    constructor() {
        this.url = "/api/news";
        this._authService = new AuthService();
        this._responseProcessor = new ResponseProcessor(this._authService);
    }

    getNews = async (request) => {
        let headers = this._authService.getAuthHeader();
        const resp =
            await fetch(`${this.url}?pageNumber=${request.pageNumber}&newsPerPage=${request.newsPerPage}&channelId=${request.channelId}`,
                {headers: headers});

        return await this._responseProcessor.processResponse(resp);
    }

    getNewsItem = async (id) => {
        let headers = this._authService.getAuthHeader();
        const resp =
            await fetch(`${this.url}/${id}`,
                {headers: headers});
        return await this._responseProcessor.processResponse(resp);
    }
}