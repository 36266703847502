import React from "react";
import { isValidUrl } from "./utils";
import { RedirectLink } from "../../../domain/redirects/RedirectLink";

interface Props {
    redirectLinks: Array<RedirectLink>;
    onRemove: (redirectLink: RedirectLink) => void;
    onChange: (redirectLink: RedirectLink) => void;
    onAddNewRedirectLink: () => void;
}

export const RedirectLinkEditor = (props: Props) => {

    const canAddNewRedirectLink = (): boolean => {
        return props.redirectLinks.every(it => it.url.trim() !== "" && isValidUrl(it.url.trim()));
    }

    const renderRedirectLinkItem = (redirectLink: RedirectLink) => {
        return <React.Fragment >
            <div className="redirect-editor__link-type-wrapper">
                <div className="checkbox-wrapper">

                    <input type="checkbox"
                        id={`redirectLinkActive-${redirectLink.id}`}
                        checked={redirectLink.active}
                        onChange={() => props.onChange({ ...redirectLink, active: !redirectLink.active })} />

                    <label className="redirect-editor__active-label" htmlFor={`redirectLinkActive-${redirectLink.id}`} >Активный</label>
                </div>
                <div className="checkbox-wrapper">

                    <input type="checkbox"
                        id={`redirectLinkRss-${redirectLink.id}`}
                        checked={redirectLink.type === "Rss"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ ...redirectLink, type: e.target.checked ? "Rss" : "Direct" })} />

                    <label className="redirect-editor__active-label" htmlFor={`redirectLinkRss-${redirectLink.id}`} >Рсс cсылка</label>
                </div>
            </div>
            <input
                className={`text-input redirect-editor__url-input ${!isValidUrl(redirectLink.url) ? 'text-input_error' : ''}`}
                type="text"
                placeholder="Адрес ссылки"
                value={redirectLink.url}
                disabled={redirectLink.id > 0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ ...redirectLink, url: e.target.value })} />
            <button className="button-link"
                type="button"
                tabIndex={-1}
                onClick={() => props.onRemove(redirectLink)}>
                Удалить</button>

        </React.Fragment >
    }

    return <React.Fragment>
        <div className="redirect-editor__section-title">
            <strong className="page-subtitle">Ссылки</strong>
        </div>
        {props.redirectLinks.map(it => {
            return <React.Fragment key={it.id}>
                {renderRedirectLinkItem(it)}
            </React.Fragment >

        })}
        <button className="button-link redirect-editor__add-link-button"
            type="button"
            disabled={!canAddNewRedirectLink()}
            onClick={props.onAddNewRedirectLink}>Добавить ссылку</button>
    </React.Fragment>
}