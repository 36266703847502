import { redirectFetch, redirectFetchFailed, redirectFetchSuccess, redirectSave, redirectSaveFailed, redirectSaveSuccess } from "./redirect-action-creators";
import { Dispatch } from "redux";
import { TRedirectAction } from "./redirect-action-types";
import { showErrorAction } from "../Error/ErrorAction";
import { RedirectPolicyService } from "../../services";
import { RedirectPolicy } from "../../domain/redirects/RedirectPolicy";

export const fetchRedirectList = () => {
    return async (dispatch: Dispatch<TRedirectAction>) => {
        const redirectService = new RedirectPolicyService();
        dispatch(redirectFetch());
        try {
            const redirects = await redirectService.fetch();
            if (redirects) {
                dispatch(redirectFetchSuccess(redirects));
            }
            else {
                const error = "Failed to fetch redirects";
                dispatch(showErrorAction(error));
                dispatch(redirectFetchFailed(error));
            }
        }
        catch (error) {
            dispatch(showErrorAction(error));
            dispatch(redirectFetchFailed(error));
        }

    }
}

export const saveRedirect = (redirect: RedirectPolicy): any => {
    return async (dispatch: Dispatch<TRedirectAction>) => {
        const redirectService = new RedirectPolicyService();
        dispatch(redirectSave());
        try {
            let result;
            if (redirect.id <= 0) {
                result = await redirectService.createRedirect(redirect);
            }
            else {
                result = await redirectService.updateRedirect(redirect);
            }

            if (result) {
                dispatch(redirectSaveSuccess());
                return true;
            }
            else {
                const error = "Unable to save redirect";
                dispatch(showErrorAction(error));
                dispatch(redirectSaveFailed(error));
            }
        }
        catch (error) {
            dispatch(showErrorAction(error));
            dispatch(redirectSaveFailed(error));
        }
        return false;
    }
}

export const removeRedirect = (redirectId: number) => {
    return async (dispatch: Dispatch<TRedirectAction>) => {
        const redirectService = new RedirectPolicyService();
        dispatch(redirectSave());
        try {
            const result = await redirectService.removeRedirect(redirectId);
            if (result) {
                dispatch(redirectSaveSuccess());
                fetchRedirectList()(dispatch);
                return true;
            }
            else {
                const error = "Unable to save redirect";
                dispatch(showErrorAction(error));
                dispatch(redirectSaveFailed(error));
            }
        }
        catch (error) {
            dispatch(showErrorAction(error));
            dispatch(redirectSaveFailed(error));
        }
        return false;
    }
}
