import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GenericModal } from '../GenericModal';
import { sendFeedback, approveFeedback } from '../../actions/Feedback/FeedbackActions';
import { TextInput, TextArea } from '../Controls';
import { Form, Formik } from "formik";
import * as Yup from "yup";

import "./Feedback.css";

export const Feedback = () => {

    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const inProcess = useSelector((state) => state.feedback.inProcess);
    const wasSend = useSelector((state) => state.feedback.wasSend);

    const onFeedbackClick = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        dispatch(approveFeedback());
    }

    const handleFormSubmit = (props) => {
        const request = {
            email: props.email,
            subject: props.subject,
            message: props.message
        };
        dispatch(sendFeedback(request, closeModal));
    }

    return (
        <React.Fragment>
            <button className="additional-nav__link"
                onClick={() => onFeedbackClick()}
            >Обратная связь
            </button>
            <GenericModal
                isOpen={showModal}
                onClose={() => closeModal()}>
                <h1 className="feedback__title">Обратная связь</h1>

                {inProcess && <p> Идет отправка сообщения</p>}
                {!inProcess && wasSend &&
                    <React.Fragment>
                        <p>Ваше сообщение успешно отправленно</p>
                        <button
                            className="feedback__button"
                            onClick={() => closeModal()}
                        >Закрыть
                        </button>
                    </React.Fragment>
                }

                {!inProcess && !wasSend &&
                    <Formik enableReinitialize
                        initialValues={{ email: "", password: "" }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => handleFormSubmit(values)}>
                        {(formProps) => (
                            <Form className="registration-block">
                                <React.Fragment>
                                    <TextInput
                                        className='feedback-modal-block__input'
                                        name="email"
                                        type="email"
                                        placeholder="Введите свой E-mail" />

                                    <TextInput
                                        className='feedback-modal-block__input'
                                        name="subject"
                                        type="input"
                                        placeholder="Тема" />

                                    <TextArea
                                        className='feedback-modal-block__input feedback-modal-block__input_message'
                                        name="message"
                                        type="input"
                                        placeholder="Сообщение" />

                                    <button
                                        className="feedback__button"
                                        disabled={inProcess || !formProps.isValid}
                                        type="submit">Отправить
                                    </button>
                                    <button
                                        className="feedback__button"
                                        onClick={() => closeModal()}
                                    >Отмена
                                    </button>
                                </React.Fragment>
                            </Form>)
                        }
                    </Formik>
                }
            </GenericModal>
        </React.Fragment>
    );
}

const validationSchema = Yup.object({
    email: Yup.string().transform(e => e.trim()).email("Поле должно содеражать валидный email").required("Это поле не может быть пустым"),
    subject: Yup.string().required("Это поле не может быть пустым").min(2, "Поле должно содержать не менее 2 символов"),
    message: Yup.string().required("Это поле не может быть пустым").min(6, "Поле должно содержать не менее 6 символов"),
});