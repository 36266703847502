export const API_URL = process.env.NODE_ENV === 'production' ? '/api' : '/api';
export const NewsPerPage = 25;
export const wideTilesPosition = [2, 11, 18];
export const BlankImagePattern = '/api/image';
export const ChannelItemByPage = 16;
export const FacebookAppId = "629338547575079";
export const GoogleClientId = "139874909060-p9d8l8umq2ggadsgu2ekpe5k1431p9ot.apps.googleusercontent.com";
export const GoogleClientSecret = "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";
export const VkAppId = "51890506";
export const MaxDescriptionLength = 1200;
export const NewsItemMaxDescriptionLength = 160;
export const DefaultRequestTimeout = process.env.NODE_ENV === 'production' ? 15000 : 300000;
