import {Component} from 'react';
import {searchNews} from '../../actions/News/NewsActions';
import {connect} from 'react-redux';
import {NewsPageContainer} from '../containers/NewsPageContainer';
import {NewsPerPage} from '../../config';

interface Props {
    channelId?: number;
    pattern: string;
    authService: any;
}

type TSearchPageProps = Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export class SearchPageImpl extends Component<TSearchPageProps> {

    componentDidUpdate(prevProps: TSearchPageProps) {
        if (prevProps.pattern !== this.props.pattern) {
            this.getNews(0);
        }
    }

    getNews = async (pageNumber: number) => {
        const {channelId, pattern} = this.props;
        await this.props.searchNews({pageNumber, newsPerPage: NewsPerPage, pattern: pattern, channelId: channelId});
    }

    render() {
        return (<NewsPageContainer {...this.props} loadItems={this.getNews}/>);
    }
}

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        searchNews: (searchRequest: any) => {
            dispatch(searchNews(searchRequest));
        }
    }
};

export const SearchPage = connect(mapStateToProps, mapDispatchToProps)(SearchPageImpl);