import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changePassword } from "../../../actions/Auth/Auth";
import * as Yup from "yup";
import { TextInput } from '../../Controls';
import { Form, Formik } from "formik";

import "./ResetPasswordConfirmation.css";

const validationSchema = Yup.object({
    password: Yup.string().required("Это поле не может быть пустым").min(6, "Поле должно содержать не менее 6 символов"),
    confirm: Yup.string().required("Это поле не может быть пустым").oneOf([Yup.ref("password")], "Пароль и подтверждение должны совпадать")
        .min(6, "Поле должно содержать не менее 6 символов")
});

export const ResetPasswordConfirmation = () => {

    const errorMessage = useSelector((state: any) => state.auth.error);
    const inProcess = useSelector((state: any) => state.auth.inProcess);
    const { email, token } = useParams<any>();
    const dispatch = useDispatch();

    const handleFormSubmit = ({ password }: any) => {
        dispatch(changePassword(email, password, token))
    }

    if (!token) {
        return <div className="confirmation__block">
            <p>Не найден токен востановления пароля</p>
        </div>
    }

    return <div className="confirmation__block">
        <div className="account-modal-block">
            <h1 className="account-modal-block__title">Изменение пароля</h1>
            <Formik enableReinitialize
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleFormSubmit(values)}>
                {() => (
                    <Form className="registration-block">
                        <Fragment>
                            {errorMessage && errorMessage.length > 0 && (
                                <span className="modal-block__error modal-block__error_summary">{errorMessage}</span>)}
                            <TextInput className='account-modal-block__input text-input text-input_large'
                                name="password"
                                type="password"
                                placeholder="Введите пароль" />

                            <TextInput className='account-modal-block__input text-input text-input_large'
                                name="confirm"
                                type="password"
                                placeholder="Введите пароль" />

                            <button className="account-modal-block__button"
                                disabled={inProcess}
                                type="submit">Изменить
                            </button>
                        </Fragment>
                    </Form>
                )}
            </Formik>
        </div>
    </div>
}