export class TextTrimService {
    trimWithEllipsis(text, maxLength) {
        if (!text)
            return '';

        text = text.trim();
        if (text.length <= maxLength)
            return text;

        text = text.substring(0, maxLength);

        if (this.isTerminatingSymbol(text[text.length - 1]))
            return text;

        var terminatingSymbolPosition = text.length - 2;
        while (terminatingSymbolPosition > 0 && !this.isTerminatingSymbol(text[terminatingSymbolPosition])) {
            terminatingSymbolPosition--;
        }
        if (terminatingSymbolPosition > 0)
            text = text.substring(0, terminatingSymbolPosition) + '...';

        return text;
    }

    isTerminatingSymbol(char) {
        const terminatingSymbols = ['.', '!', '?', ';', ' '];
        return terminatingSymbols.includes(char);
    }
}