import {useEffect} from "react";
import Masonry from 'react-masonry-component';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {fetchNews} from "../../actions/News/NewsActions";
import {NewsItem} from "../NewsItem";

import "./RecommendedBlock.css";

interface Props {
    currentNewsId: number;
    recommendedNewsCount?: number;
}

export const RecommendedBlock = (props: Props) => {
    const masonryOptions = {
        percentPosition: true,
        transitionDuration: 500,
        columnWidth: '.news-item',
        updateOnEachImageLoad: true,
        gutter: 20
    };

    const newsCount = props.recommendedNewsCount ?? 6;

    const newsList = useSelector((state: any) => state.news.newsList?.filter((it: any) => it.id !== props.currentNewsId)
        .slice(0, newsCount));
    // const loading = useSelector((state: any) => state.news.loading);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (newsList.length < newsCount) {
            dispatch(fetchNews(1, 10, 0));
        }
    }, [props.currentNewsId]);


    const onItemClick = (newsIndex: number) => {
        const news = newsList[newsIndex];
        const link = `/news/${news.id}-${news.slug}`;
        history.push(link);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const M: any = Masonry;
    return <div>
        <div className={`news-container big-tiles recommended`}>
            <M
                className={'news-block'}
                elementType={'div'}
                options={masonryOptions}
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}>
                {newsList.map((item: any, index: number) => (
                    <NewsItem key={item.id} news={item} index={index} onNewsItemClicked={onItemClick}/>))}
            </M>
        </div>
    </div>

}