import React, { useState, useEffect } from "react";
import { matchPath, useLocation, useHistory } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as _ from "lodash";

import "./SearchPanel.css";
import { SearchService } from "../../../services/SearchService";

const defaultDebounceTime = 30;
const ChannelPath = "/channels/:idWithName";


export const SearchPanel = () => {
    const [pattern, setPattern] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedChannelId, setSelectedChannelId] = useState(undefined);
    const location = useLocation();
    const history = useHistory();
    const searchService = new SearchService();

    useEffect(() => {
        const channelId = getSelectedChannelId();
        setOptions([]);
        setSelectedChannelId(channelId);
    }, [location]);

    const debounceFn = _.debounce(async (prefix) => {
        const result = await searchService.findByPrefix(prefix, selectedChannelId);
        setOptions(result);
    }, defaultDebounceTime);

    const onChange = (event, value) => {
        if (!event) return;
        const searchPattern = typeof (value) === "string" ? value : value.label;

        setPattern(searchPattern ?? "");
        setOptions([]);

        if (value.length >= 3) {
            debounceFn(value);
        }
    }

    const getSearchLink = () => {
        if (pattern.trim().length === 0)
            return '';

        let searchUrl = `/search/${pattern}`;
        const selectedChannelId = getSelectedChannelId();
        if (selectedChannelId)
            searchUrl += `/${selectedChannelId}`;
        return searchUrl;
    }

    const getSelectedChannelId = () => {
        const match = matchPath(location.pathname, {
            path: ChannelPath,
            exact: true,
            strict: false
        });
        const idWithName = match?.params.idWithName;
        if (idWithName) {
            const match = idWithName.match(/^\d+/);
            if (match) {
                const channelId = match[0];
                const channelIdValue = Number(channelId);
                return channelIdValue;
            }

        }
        return undefined;
    }

    const doSearch = (event) => {
        event.preventDefault();

        if (pattern.trim().length <= 2)
            return;

        const url = getSearchLink();
        history.push(url);
        setPattern("");
    }

    return (
        <form className="search-pannel" onSubmit={doSearch}>
            <Autocomplete
                disablePortal
                className="search-pannel__input"
                value={pattern}
                freeSolo={true}
                type="search"
                options={options}
                filterOptions={(x) => x}
                onChange={onChange}
                onInputChange={onChange}
                disableClearable={true}
                clearIcon=""
                renderInput={(params) => <TextField {...params} className="search-pannel__input" placeholder="Поиск" />}
            />

            <button
                className={`search-pannel__button ${pattern?.trim()?.length === 0 ? 'search-pannel__input_disabled' : ' '}`}
                disabled={pattern.trim().length <= 2}
                onClick={doSearch}> Search
            </button>
        </form>
    );
}