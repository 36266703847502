export class BaseReducer {
    handlers: any;
    constructor(handlers: any) {
        this.handlers = handlers;
    }

    getReducer = (key: any) => {
        return this.handlers[key];
    }
}
