import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from 'history';
import App from './App';
import { AUTH_USER, FAVORITES_LOADED } from './actions/types';
import { NewsService } from './services/NewsService';
import { ErrorBoundary } from './components/Utils/ErrorBoundary';
import { NewsServiceProvider } from './components/Context/NewsServiceContext';
import { ChannelServiceProvider } from './components/Context/ChannelServiceContext';
import { ChannelService } from './services/ChannelService';
import { ViewSelectorService } from './services/ViewSelectorService';
import { viewTypeChanged } from './actions/ViewSelector/ViewSelectorActions';
import { FavoritesService } from './services/FavoritesService';
import configureStore from "./store/configureStore";



// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(history);

const user = localStorage.getItem('user');
const auth_token = localStorage.getItem('auth_token');
if (user && auth_token) {
    store.dispatch({ type: AUTH_USER });
    const favoritesService = new FavoritesService();
    favoritesService.getFavoriteIds()
        .then(ids => {
            store.dispatch({ type: FAVORITES_LOADED, payload: ids });
        });
}

const viewSelectorService = new ViewSelectorService();
viewSelectorService.getTilesMode()
    .then((viewMode) => store.dispatch(viewTypeChanged(viewMode)));

const newsService = new NewsService();
const channelService = new ChannelService();

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ErrorBoundary>
                <NewsServiceProvider value={newsService}>
                    <ChannelServiceProvider value={channelService}>
                        <App />
                    </ChannelServiceProvider>
                </NewsServiceProvider>
            </ErrorBoundary>
        </BrowserRouter>
    </Provider>
);

// registerServiceWorker();
