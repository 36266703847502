import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { changeUserPassword } from '../../actions/Profile/ProfileActions';
import { TextInput } from '../Controls';
import { Form, Formik } from "formik";
import * as Yup from "yup";

import "./Profile.css";

export const ProfileChangePassword = () => {

    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const error = useSelector((state) => state.userProfile.error);

    const success = () => {
        setEditMode(false)
    }

    const handleFormSubmit = (props) => {

        dispatch(changeUserPassword(props.oldPassword, props.password, success));
    }

    if (editMode) {
        return (
            <div className="profile__block profile__block_edit-mode">
                <button
                    className="profile__block__view-mode-button close"
                    onClick={() => setEditMode(false)}>X
                </button>
                <Formik enableReinitialize
                    initialValues={{ oldPassword: "", password: "", confirmPassword: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleFormSubmit(values)}>
                    {(formProps) => (
                        <Form className="profile__change-password-form">
                            <React.Fragment>
                                <TextInput
                                    className='profile__block__input'
                                    name="oldPassword"
                                    type="password"
                                    placeholder="Старый пароль" />

                                <TextInput
                                    className='profile__block__input'
                                    name="password"
                                    type="password"
                                    placeholder="Пароль" />

                                <TextInput
                                    className='profile__block__input'
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Подтверждение Пароля" />

                                <button
                                    className="profile__block_save-button"
                                    disabled={!formProps.isValid}
                                    type="submit">Сохранить
                                </button>

                                {error &&
                                    <div className="error-container"> {error}</div>}
                            </React.Fragment>
                        </Form>)
                    }
                </Formik>
            </div>
        );
    }

    return (
        <div className="profile__block">
            <span className="profile__field">Пароль</span>
            <span className="profile__value">************</span>
            <button
                className="profile__action profile__action_edit"
                onClick={() => setEditMode(true)}> Редактировать
            </button>
        </div>
    );
}

const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Это поле не может быть пустым").min(6, "Поле должно содержать не менее 6 символов"),
    password: Yup.string().required("Это поле не может быть пустым").min(6, "Поле должно содержать не менее 6 символов"),
    confirmPassword: Yup.string().required("Это поле не может быть пустым").min(6, "Поле должно содержать не менее 6 символов")
        .oneOf([Yup.ref('password')], "Подтверждение должно совпадать с паролем"),
});