import * as actionTypes from '../actions/types';

const initialState = {
    newsList: [],
    pageNumber: 0,
    selectedChannelId: undefined,
    selectedNewsItemIndex: undefined,
    newsWasShown: false,
    loading: true,
    showModal: false
};

const newsItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEWS_LOADED:
            return {
                ...initialState,
                newsList: [...state.newsList, ...action.payload],
                pageNumber: state.pageNumber + 1,
                selectedChannelId: state.selectedChannelId,
                selectedNewsItemIndex: state.selectedNewsItemIndex,
                newsWasShown: state.newsWasShown,
                loading: false
            };
        case actionTypes.NEWS_ITEM_LOADED:
            return {
                ...state,
                loading: false,
                newsList: [...state.newsList, action.payload]
            };
        case actionTypes.NEWS_CLEAN:
            return {
                ...state,
                newsList: []
            };
        case actionTypes.NEWS_ITEM_SELECTED:
            return {
                ...state,
                selectedNewsItemIndex: (action.payload !== null)
                    ? action.payload
                    : state.newsList.length - 1,
                showModal: true,
                newsWasShown: true
            };
        case actionTypes.NEWS_CHANNEL_CHANGED:
            return {
                ...state,
                selectedChannelId: action.payload,
                pageNumber: 0
            }
        case actionTypes.MODAL_NEWS_HIDE:
            return {
                ...state,
                showModal: false,
                selectedNewsItemIndex: undefined
            }
        case actionTypes.NEWS_RELOADED:
            return {
                ...state,
                newsList: action.payload,
                pageNumber: 1,
                loading: false,
            }
        case actionTypes.SHOW_LOADER:
            return {
                ...state,
                loading: true
            };
        case actionTypes.HIDE_LOADER:
            return {
                ...state,
                loading: false
            }


        default:
            return state;
    }
}

export {newsItemReducer};
