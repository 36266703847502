import React, {Component} from 'react';
import {connect} from 'react-redux';
import {GenericModal} from '../../GenericModal/GenericModal';
import {Registration} from '../Registration';
//import { externalSigninUser } from '../../../actions/Auth/Auth';
import {showRegistrationModalAction} from '../../../actions/Modal/ModalActions';

import './RegistrationModal.css';

class RegistrationModalImpl extends Component {
    constructor(props) {
        super(props);
        this.originalAddress = undefined;
    }

    onOpenModal = () => {
        if (this.props.show && window.location.pathname !== '/registration') {
            window.history.pushState({}, '', '/registration');
        } else if (!this.props.show && window.location.pathname === '/registration') {
            window.history.go(-1);
        }
        this.originalAddress = !window.location.pathname.startsWith("/login")
        || !window.location.pathname.startsWith("/registration")
            ? window.location.pathname
            : "/";
    }

    closeModal = () => {
        this.props.showRegistrationModal(false);
        document.body.removeAttribute('style');
        document.body.removeEventListener('scroll', this.stopScroll);
        window.history.pushState({}, {}, '/');
    }

    render() {
        return (
            <GenericModal
                className="account-modal"
                isOpen={this.props.show}
                onClose={this.closeModal}
                onOpen={this.onOpenModal}>

                <Registration onLink={this.closeModal}/>
            </GenericModal>
        );
    }
}

const mapStateToProps = (state) => {
    return {show: state.registrationModal.showModal};
};

const mapDispatchToProps = (dispatch) => {
    return {
        showRegistrationModal: (show) => {
            dispatch(showRegistrationModalAction(show));
        }
    }
};

export const RegistrationModal = connect(mapStateToProps, mapDispatchToProps)(RegistrationModalImpl);