import React from 'react';
import { ChannelFilter } from '../ChannelFilter';
import { ChannelSearch } from '../ChannelSearch';

export const ChannelControls = () => {
    return (
        <div className="channel-controls">
            <ChannelSearch />
            <ChannelFilter />
        </div>
    );
}