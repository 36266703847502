import React, { Component } from "react";

export const withDefaultNoFollowMetaHoc = (WrappedComponent) => {

    class withDefaultNoFollowMeta extends Component {

        componentDidMount() {
            document.querySelector('meta[name="robots"]')?.setAttribute("content", "noindex, nofollow");
        }

        render() {
            return (<WrappedComponent  {...this.props} />);
        }

    }
    return withDefaultNoFollowMeta;
}
