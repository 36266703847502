import React from 'react';

import './ChannelSliderLoader.css';

function ChannelSliderLoader() {
    return (<div className="channel-loader-container">
        <div className="channel-loader"></div>
    </div>);
}


export {ChannelSliderLoader};