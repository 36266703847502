import { SubscriberService } from '../../services/SubscriberService';
import { fetchChannels, sliderChannelSelected } from './SliderChannelActions';
import { fetchNews } from '../News/NewsActions';
import { NewsPerPage } from '../../config';
import { showChannelModalAction } from '../Modal/ModalActions';
import { showErrorAction } from '../Error/ErrorAction';
import { channelAdded, subscriptionLoaded, subscriptionLoadedSuccess, subscriptionLoadedFailed, channelPageChanged, channelFiterChanged, channelSubscriptionsChanged, subscriptionCancel, channelValidating } from "./subscription-action-creators";
import { ChannelService } from '../../services/ChannelService';

export const fetchChannelForSubscription = () => {
    return async (dispatch) => {
        const channelService = new ChannelService();
        dispatch(subscriptionLoaded());
        try {
            const channels = await channelService.getChannelsForSubscription();
            dispatch(subscriptionLoadedSuccess(channels));
        }
        catch (error) {
            dispatch(subscriptionLoadedFailed(error));
            dispatch(showErrorAction(error));
        }
    }
}

export const changePage = (pageNumber) => {
    return async (dispatch) => {
        dispatch(channelPageChanged(pageNumber))
    }
}

export const changeFilter = (filter) => {
    return async (dispatch) => {
        dispatch(channelFiterChanged(filter));
    }
}

export const changeChannelSubscription = (id) => {
    return async (dispatch) => {
        dispatch(channelSubscriptionsChanged(id));
    }
}

export const cancelSubscrition = () => {
    return async (dispatch) => {
        dispatch(subscriptionCancel());
    }
}

export const saveSubscription = (changedChannels) => {
    return async (dispatch) => {
        const subscriberService = new SubscriberService();
        let channels = changedChannels.filter(c => c.currentSubsctiption !== c.originalSubscribtion);
        let errors = [];

        for (let channel of channels) {
            try {
                if (channel.currentSubsctiption) {
                    await subscriberService.subscribe(channel.id);
                }
                else {
                    await subscriberService.unsubscribe(channel.id);
                }

            } catch (error) {
                errors.push(error);
            }
        }

        if (errors.length === 0) {
            dispatch(fetchChannels());
            dispatch(fetchChannelForSubscription());
            dispatch(sliderChannelSelected(-1));
            dispatch(fetchNews(0, NewsPerPage, undefined));
            dispatch(cancelSubscrition());
            dispatch(showChannelModalAction(false));
        } else
            dispatch(showErrorAction(errors));
    };
}

export const addChannelByUrl = (channelUrl) => {
    return async (dispatch) => {
        dispatch(channelValidating(true));
        const channelService = new ChannelService();
        try {
            const channel = await channelService.createChannel(channelUrl);
            dispatch(channelAdded(channel));
        }
        catch (error) {
            dispatch(channelValidating(false, error));
        }
    }
}