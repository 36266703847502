import { API_URL } from '../config';
import { ResponseProcessor } from './ResponseProcessor';

export class AuthService {
    constructor() {
        this.baseUrl = `${API_URL}/auth`;
        this.registrationKey = 'registration_shown';
        this.responseProcessor = new ResponseProcessor(this);
    }

    isAuthenticated() {
        return localStorage.getItem('user') && localStorage.getItem('auth_token');
    }

    isInRole(roleName) {
        const role = localStorage.getItem('user_role');
        return roleName === role;
    }

    isAdmin() {
        return this.isInRole("Admin");
    }

    login = async (email, password) => {
        if (!email || !password)
            return;

        this.logout();

        const data = JSON.stringify({ Login: email, Password: password });
        const loginUrl = `${this.baseUrl}/login`;
        const response = await fetch(loginUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: data
        });

        if (response.status === 406) {
            window.location = `/resend/${email}`;
        }

        const body = await this.responseProcessor.processResponse(response, true);

        const userInfo = body.token;
        this.saveAuthData(userInfo.userName, userInfo.authToken, userInfo.role);
        return userInfo.user_name;
    }

    externalLogin = async (email, token, provider) => {
        const data = JSON.stringify({ ProviderName: provider, ProviderKey: token, Email: email });
        const externalLoginUrl = `${this.baseUrl}/external`
        const response = await fetch(externalLoginUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer: ${token}`
            },
            body: data
        });

        const body = await this.responseProcessor.processResponse(response, true);
        const userInfo = body.token;
        this.saveAuthData(userInfo.userName, userInfo.authToken, userInfo.role);
        return userInfo.user_name;
    }

    register = async (email, password, confirmPassword) => {
        const data = JSON.stringify({ Email: email, Password: password, ConfirmPassword: confirmPassword });
        const registerUrl = `${this.baseUrl}/registration`;

        const response = await fetch(registerUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: data
        });
        await this.responseProcessor.processResponse(response, true);
        return response.ok;
    }

    confirmEmail = async (email, token) => {
        const data = JSON.stringify({ Email: email, Token: token });
        const confirmUrl = `${this.baseUrl}/confirm`;
        const response = await fetch(confirmUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: data
        });

        return response.ok;
    }

    resendVerification = async (email) => {
        const data = JSON.stringify({ email: email });
        const confirmUrl = `${this.baseUrl}/resend`;
        const response = await fetch(confirmUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: data
        });
        return response.ok;
    }

    changeEmail = async (email) => {
        let headers = this.getAuthHeader();
        const data = JSON.stringify({ Email: email });
        const changeEmailUrl = `${this.baseUrl}/change-email`;
        const response = await fetch(changeEmailUrl, {
            method: "POST",
            headers: headers,
            body: data
        });
        return response.status;
    }

    changeUserPassword = async (oldPassword, newPassword) => {
        let headers = this.getAuthHeader();
        const data = JSON.stringify({ OldPassword: oldPassword, NewPassword: newPassword });
        const confirmUrl = `${this.baseUrl}/change-password`;
        const response = await fetch(confirmUrl, {
            method: "POST",
            headers: headers,
            body: data
        });

        return response.status;
    }

    resetPassword = async (email) => {
        const data = JSON.stringify({ Email: email });
        const resetPasswordUrl = `${this.baseUrl}/reset-password-request`;
        const response = await fetch(resetPasswordUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: data
        });

        if (!response.ok) {
            throw Error("Произошла ошибка при обработке запроса");
        }

    }

    resetPasswordByToken = async (email, password, token) => {
        const data = JSON.stringify({ Email: email, Password: password, Token: token });
        const resetPasswordUrl = `${this.baseUrl}/reset-password`;
        const response = await fetch(resetPasswordUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: data
        });

        if (!response.ok) {
            throw Error("Произошла ошибка при обработке запроса");
        }

    }

    getAuthHeader() {
        const authToken = localStorage.getItem('auth_token');
        if (authToken) {
            var headers = {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            };
            return headers;
        }
        return {}
    }

    isFirstVisit = () => {
        const bannerShown = localStorage.getItem(this.registrationKey);
        if (!bannerShown && !this.isAuthenticated())
            return true;

        return false;
    }

    saveAuthData = (email, token, role) => {
        localStorage.setItem("user", email);
        localStorage.setItem("auth_token", token);
        if (role)
            localStorage.setItem("user_role", role);
    }

    registrationShown = () => {
        localStorage.setItem(this.registrationKey, "true");
    }

    logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("auth_token");
        localStorage.removeItem("user_role");
    }

    checkUnautorizeResult = (satusCode) => {
        if (satusCode === 401 || satusCode === 403) {
            localStorage.removeItem('user');
            localStorage.removeItem('auth_token');
            window.location = '/login';
        }
    }
}