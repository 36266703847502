import * as actionTypes from '../actions/types';
import {ViewSelectorService} from '../services/ViewSelectorService';

const viewSelectorService = new ViewSelectorService();

const initialState = {
    viewMode: viewSelectorService.BIG_MODE
};

const viewSelectorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VIEW_SELECTOR_MODE_CHANGED:
            return {
                viewMode: action.payload
            };

        default:
            return state;
    }
}

export {viewSelectorReducer};
