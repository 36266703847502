import React from 'react';
import { ChannelList } from '../Channels/ChannelList';
import { ChannelControls } from '../Channels/ChannelControls';

export const ChannelPage = () => {
    return (
        <React.Fragment>
            <ChannelControls />
            <ChannelList />
        </React.Fragment>
    );

}

