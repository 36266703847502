import * as actionTypes from '../types';
import { FeedbackService } from '../../services/FeedbackService';
import { showErrorAction } from '../Error/ErrorAction';

const feedbackService = new FeedbackService();

const feedbackSendSuccess = () => {
    return {
        type: actionTypes.FeedbackActionTypes.FEEDBACK_SEND_SUCCESS
    };
};

const feedbackSend = () => {
    return {
        type: actionTypes.FeedbackActionTypes.FEEDBACK_SEND
    };
};

const feedbackSendFailed = () => {
    return {
        type: actionTypes.FeedbackActionTypes.FEEDBACK_SEND_FAILED
    };
};

const feedbackSendApprove = () => {
    return {
        type: actionTypes.FeedbackActionTypes.FEEDBACK_SEND_APPROVE
    };
};

const sendFeedback = (request) => {
    return async (dispatch) => {
        try {
            dispatch(feedbackSend());
            await feedbackService.sendFeedback(request);
            dispatch(feedbackSendSuccess());

        } catch (error) {
            dispatch(showErrorAction('Не удалось отправить Ваше сообщение'));
        }
    };
};

const approveFeedback = () => {
    return async (dispatch) => {
        try {
            dispatch(feedbackSendApprove());

        } catch (error) {
            dispatch(showErrorAction('Не удалось отправить Ваше сообщение'));
        }
    };
}

export {
    feedbackSend,
    feedbackSendSuccess,
    feedbackSendFailed,
    sendFeedback,
    approveFeedback
};