import * as actionTypes from '../actions/types';

const initialState = {
    loading: false,
    newsIds: null,
};

const favoritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FAVORITES_LOADED:
            return {
                ...state,
                loading: false,
                newsIds: action.payload
            };
        case actionTypes.FAVORITES_ADD:
            return {
                ...state,
                loading: false,
                newsIds: [...state.newsIds, action.payload]
            };
        case actionTypes.FAVORITES_REMOVE: {
            let newIdList = [...state.newsIds];
            const index = state.newsIds.findIndex(it => it === action.payload);
            if (index !== -1) {
                newIdList = [...state.newsIds.slice(0, index), ...state.newsIds.slice(index + 1)];
            }

            return {
                ...state,
                newsIds: newIdList
            };
        }
        default:
            return state;
    }
}

export {favoritesReducer};
