import React, { Component } from 'react';
import { GenericModal } from '../../GenericModal/GenericModal';
import { connect } from 'react-redux';
import { hideLoginModalAction } from '../../../actions/Modal/ModalActions';
import { Login } from "../Login";

import './LoginModal.css';

class LoginModalImpl extends Component {

    componentDidUpdate() {
        if (this.props.show && window.location.pathname !== '/login') {
            window.history.pushState({}, '', '/login');
        }
    }

    closeModal = () => {
        this.props.closeModal();
        document.body.removeAttribute('style');
        document.body.removeEventListener('scroll', this.stopScroll);
    }

    render() {
        return (
            <GenericModal
                className="account-modal"
                isOpen={this.props.show}
                onClose={this.closeModal}>
                <Login onLink={this.closeModal} />
            </GenericModal>
        );
    }
}

const mapStateToProps = (state) => {
    return { show: state.loginModal.showModal };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: () => {
            dispatch(hideLoginModalAction());
        }
    }
};

export const LoginModal = connect(mapStateToProps, mapDispatchToProps)(LoginModalImpl);
