import React, {Component} from "react";
import {connect} from 'react-redux';
import {GenericModal} from '../GenericModal';
import {changeEmail} from '../../actions/Profile/ProfileActions';


class ProfileChangeEmailImpl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            email: this.props.email,
            error: null,
            closeModal: false
        };
        this.success = this.success.bind(this);
    }

    onChange = (event) => {
        this.setState({email: event.target.value});
    }

    onButtonSaveClicked = () => {
        const originalEmail = this.props.email;
        let {email} = this.state;
        email = email ? email.trim() : originalEmail;
        if (email === originalEmail)
            this.setState({editMode: false});
        else {
            this.props.changeEmail(email);
        }
    }

    closeModal = () => {
        this.setState({closeModal: true});
        this.setState({editMode: false});
        window.location = '/login';
    }

    success = () => {
        this.setState({editMode: false});
        //show info modal
    }

    render() {
        const {email} = this.props;
        if (this.state.editMode) {
            return (
                <div className="profile__block profile__block_edit-mode">
                    <button
                        className="profile__block__view-mode-button close"
                        onClick={() => this.setState({editMode: false})}>X
                    </button>
                    <span className="profile__field">Email адрес:</span>
                    {this.state.error &&
                        <span className="profile__block__error">
                            {this.state.error}
                        </span>}
                    <input
                        className="profile__block__input"
                        value={this.state.email}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        type="email"
                        placeholder="Введите Email адрес"/>
                    <button
                        className="profile__block_save-button"
                        onClick={e => this.onButtonSaveClicked(e)}>Сохранить
                    </button>

                    <GenericModal
                        isOpen={!this.state.closeModal && (this.props.emailChanged || this.props.emailChanging || this.state.closeModal)}
                        onClose={this.closeModal}
                    >
                        {this.props.emailChanging &&
                            <React.Fragment>
                                <strong className="error-modal__title">Изменение Email адреса </strong>
                                <p className="error-modal__body">Происходит валидация нового Email адреса</p>
                            </React.Fragment>}
                        {this.props.emailChanged &&
                            <React.Fragment>
                                <strong className="error-modal__title">Изменение Email адреса </strong>
                                <p className="error-modal__body">На Ваш новый адрес отправлено письмо для
                                    подтверждения</p>
                            </React.Fragment>}
                        <button
                            className="modal-controls__button"
                            onClick={this.closeModal}>Закрыть
                        </button>
                    </GenericModal>
                </div>

            );
        }

        return (
            <div className="profile__block">
                <span className="profile__field">Email адрес:</span>
                <span className="profile__value">{email}</span>
                <button
                    className="profile__action profile__action_edit"
                    onClick={() => this.setState({editMode: true})}>
                    Редактировать
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.userProfile.profile,
        loading: state.userProfile.loading,
        emailChanged: state.userProfile.emailChangedSuccess,
        emailChanging: state.userProfile.emailChangingInFligth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeEmail: (email) => {
            dispatch(changeEmail(email));
        }
    }
};

export const ProfileChangeEmail = connect(mapStateToProps, mapDispatchToProps)(ProfileChangeEmailImpl);