import { showErrorAction } from '../Error/ErrorAction';
import { sliderChannelFetched } from "./SliderChannelActions";

import {
    channelLoaded,
    channelChange,
    channelChangeSuccess,
    channelChangeFailed
} from "./channel-action-creators";
import { ChannelService } from '../../services/ChannelService';


export const changeChannel = (channel) => {
    return async (dispatch, getState) => {
        const channelService = new ChannelService();
        dispatch(channelChange());

        try {
            const result = await channelService.changeChannel(channel);
            if (result) {
                dispatch(channelChangeSuccess());

                updateLoadedChannel(channel, dispatch, getState);
                updateSliderChannel(channel, dispatch, getState);
                return result;
            }
        }
        catch (error) {
            dispatch(showErrorAction(error));
            dispatch(channelChangeFailed(error));
        }
    }
}

const updateLoadedChannel = (channel, dispatch, getState) => {
    const loadedChannels = getState().channel.channels;
    const refinedChannels = loadedChannels.map(c => {
        if (c.id === channel.id) {
            c.name = channel.name;
            c.url = channel.url;
            c.active = channel.active;

        }
        return c;
    });
    dispatch(channelLoaded(refinedChannels));
}

const updateSliderChannel = (channel, dispatch, getState) => {
    const loadedChannels = getState().channelSlider.channels;
    const refinedChannels = loadedChannels.map(c => {
        if (c.id === channel.id) {
            c.name = channel.name;
            c.url = channel.url;
            c.active = channel.active;

        }
        return c;
    });
    dispatch(sliderChannelFetched(refinedChannels));
}

export { channelLoaded }