import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { Link, withRouter } from "react-router-dom";
import { fetchChannels } from "../../actions/Channels/SliderChannelActions";
import { ChannelSliderLoader } from "./ChannelSliderLoader";

import "pure-react-carousel/dist/react-carousel.es.css";
import "./ChannelSlider.css";

const tableWidth = 764;
const desktopWidth = 1170;

const mobileSliderChannelCount = 4;
const tabletSliderChannelCount = 6;
const desktopSliderChannelCount = 8;

class ChannelSliderImpl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0
        };
    }

    getVisibleSlidesCount() {
        if (window.innerWidth < tableWidth)
            return mobileSliderChannelCount;
        if (window.innerWidth >= tableWidth && window.innerWidth < desktopWidth)
            return tabletSliderChannelCount;
        return desktopSliderChannelCount;
    }

    componentDidMount() {
        if (!this.props.channels || this.props.channels.length === 0)
            this.props.fetchChannels(this.setupSlider);
    }

    setupSlider = channels => {
        const { selectedChannelId } = this.props;
        if (selectedChannelId) {
            const index = channels.findIndex(c => c.id === selectedChannelId);
            if (index !== -1)
                this.setState({ currentIndex: index });
        }
    };

    normalizeChannelName(channelName) {
        return channelName.trim().length > 13
            ? `${channelName.substring(0, 11)}...`
            : channelName;
    }

    getChannelId = () => {

        if (this.props.location?.pathname === "/favorites")
            return -2;

        const slug = this.props.match?.params?.idWithName;
        if (!slug)
            return -1;
        const match = slug.match(/^(\d+)-.+/);
        if (match && match[1])
            return Number(match[1]);
        return null;
    }

    renderChannelsSlides = channels => {
        const selectedChannelId = this.getChannelId();

        return channels.map((channel, index) => {
            const normalizedChannelName = this.normalizeChannelName(
                channel.name
            );
            return (
                <Slide index={index} key={index}
                    className={`slider__item${channel.id === selectedChannelId ? "  slider__item_active" : ""}`}>
                    <Link
                        className={`slider__channel${channel.id === selectedChannelId ? "  slider__channel_active" : ""}`}
                        to={channel.id < 0 ? channel.url : `/channels/${channel.id}-${channel.name}`}>
                        {normalizedChannelName}
                    </Link>
                </Slide>
            );
        });
    };

    render() {
        if (this.props.loading)
            return <ChannelSliderLoader />;
        const visibleSlides = this.getVisibleSlidesCount();
        if (this.props.channels.length === 0)
            return <div className="carousel-container" />;

        return (
            <div className="carousel-container">
                <CarouselProvider
                    naturalSlideWidth={120}
                    naturalSlideHeight={125}
                    totalSlides={this.props.channels.length}
                    visibleSlides={visibleSlides}
                    currentSlide={this.state.currentIndex}
                >
                    <ButtonBack className="carousel__control">
                        <svg width="20" height="33" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 2L3 16.5L18 31" strokeWidth="3" strokeLinecap="round" />
                        </svg>

                    </ButtonBack>
                    <Slider>
                        {this.renderChannelsSlides(this.props.channels)}
                    </Slider>

                    <ButtonNext className="carousel__control">
                        <svg width="20" height="33" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.89368 2L17 16.5L1.89368 31" strokeWidth="3" strokeLinecap="round" />
                        </svg>

                    </ButtonNext>
                </CarouselProvider>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedChannelId: state.channelSlider.selectedChannelId,
        loading: state.channelSlider.loading,
        error: state.channelSlider.error,
        channels: state.channelSlider.channels
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchChannels: postAction => {
            dispatch(fetchChannels(postAction));
        }
    };
};

export const ChannelSlider = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChannelSliderImpl));
