import "./Loader.css";

interface Props {
    visible: boolean;
    message?: string;
}

export const Loader = (props: Props) => {

    const { visible, message } = props;

    if (!visible)
        return null;

    return (
        <div className="spinner-container">
            <div className="spinner"></div>
            <p className="spinner__message">{message}</p>
        </div>
    );
}