import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubscriberService } from '../../../services/SubscriberService';
import { showChannelModalAction } from '../../../actions/Modal/ModalActions';
import { AuthService } from '../../../services/AuthService';
import * as moment from "moment";

import './ChannelItem.css';


class ChannelItemImpl extends Component {

    constructor(props) {
        super(props);
        this.subscriberService = new SubscriberService();
        this.authService = new AuthService();
    }

    onSubscribeClicked = () => {
        let { subscribed, id } = this.props.channel;
        this.props.onChange({ id: id, subscribed: !subscribed });
    }

    renderChannelImage = (imageUrl) => {
        if (imageUrl) {
            return (<div className="channel__image"
                style={{ backgroundImage: `url("${imageUrl}")` }}>
            </div>);
        }
        return null;
    }

    onEditChannelClick = (event) => {
        if (this.props.changedChannels.length > 0) {
            const response = confirm("У вас есть несохраненные изменения. Проложить переход без сохранений?");
            if (response) {
                this.props.closeModal();
            }
            else {
                event.preventDefault();
                event.stopPropagation();
            }
        }
        else {
            this.props.closeModal();
        }
    }

    renderLastUpdateDate(lastUpdateDate) {
        if (this.authService.isAdmin()) {
            return <div className="channel__last-update-date">Обновлен: {moment(lastUpdateDate).format("DD.MM.YYYY HH:mm:ss")}</div>
        }
        return null;
    }

    render() {
        const { id, name, baseUrl, description, subscribed, imageUrl, isHidden, active, lastUpdateDate } = this.props.channel;
        const isAdmin = this.authService.isAdmin();

        if (!isAdmin && !active) {
            return null;
        }

        return (
            <div className={`channel ${isHidden ? ' channel_hidden' : ''}`}>
                {this.renderChannelImage(imageUrl)}
                <div className="channel__details">
                    <span className={`channel__title ${!active ? 'text-disabled' : ''}`}>{name}</span>
                    <p className="channel__description">{description}</p>
                    <a className="channel__url" href={baseUrl} target="_blank" rel="noopener noreferrer">{baseUrl}</a>
                    {this.renderLastUpdateDate(lastUpdateDate)}
                </div>
                <div className='channel__control-buttons'>
                    <button
                        className={`channel__subscribe ${(subscribed && active) ? 'channel__subscribe_subscribed' : ' '}`}
                        disabled={!active}
                        title={!active ? 'Канал неактивен' : 'Подписаться'}
                        onClick={this.onSubscribeClicked}>+
                    </button>
                    {
                        isAdmin && <Link
                            className="channel__subscribe"
                            to={`/channels/edit/${id}`}
                            onClick={this.onEditChannelClick}>✎
                        </Link>
                    }
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.channelModal.showModal,
        changedChannels: state.subscriber.changedChannels
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: () =>
            dispatch(showChannelModalAction(false))

    }
};

export const ChannelItem = connect(mapStateToProps, mapDispatchToProps)(ChannelItemImpl);
