import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../../actions/types";
import { LoginModal } from "../../Auth/LoginModal/LoginModal";
import { ResetPasswordModal } from "../../Auth/ResetPasswordModal";
import { showLoginModalAction } from "../../../actions/Modal/ModalActions";
import { AuthService } from "../../../services/AuthService";
import { RegistrationModal } from "../../Auth/RegistrationModal/RegistrationModal";

import "./LoginPanel.css";

class LoginPanelImpl extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            showUserMenu: false
        };
        this.authService = new AuthService();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.node.current) {
            const domNode = ReactDOM.findDOMNode(this.node.current);
            if (!domNode.contains(e.target))
                this.setState({ showUserMenu: false });
        }
    }

    onLogin = () => {
        this.props.showModal(true);
    };

    onLogout = () => {
        this.props.logout();
        this.onCloseUserMenu();
        this.authService.logout();
        window.location = "/";
    };

    onCloseUserMenu = () => {
        this.setState({ showUserMenu: false });
    };

    onUserButtonClick = () => {
        this.setState({ showUserMenu: !this.state.showUserMenu });
    };

    render() {
        const isAdmin = this.authService.isAdmin();

        if (this.props.authenticated) {
            let userMenuClass = `login-pannel__user-menu ${this.state.showUserMenu ? " login-pannel__user-menu_opened" : ""
                }`;
            return (
                <div className="login-pannel" ref={this.node}>
                    <button
                        className="login-pannel__user"
                        onClick={this.onUserButtonClick}
                    >{`${this.props.userName || localStorage.getItem('user')}`}</button>
                    <ul className={userMenuClass}>
                        <li className="login-pannel__user-menu-item">
                            <Link
                                className="login-pannel__link"
                                to="/profile"
                                onClick={this.onCloseUserMenu}
                            >
                                Редактировать профиль
                            </Link>
                        </li>
                        {isAdmin &&
                            <li className="login-pannel__user-menu-item">
                                <Link
                                    className="login-pannel__link"
                                    to="/redirects"
                                    onClick={this.onCloseUserMenu}
                                >
                                    Редиректы
                                </Link>
                            </li>
                        }
                        <li className="login-pannel__user-menu-item">
                            <button className="login-pannel__link" onClick={this.onLogout}>
                                Выход
                            </button>
                        </li>
                    </ul>
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="login-pannel">
                    <button className="login-pannel__login" onClick={this.onLogin}>
                        Login
                    </button>
                </div>
                <LoginModal />
                <RegistrationModal />
                <ResetPasswordModal />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModal: state.loginModal.showModal,
        authenticated: state.auth.authenticated,
        userName: state.auth.userName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showModal: show => {
            dispatch(showLoginModalAction(show));
        },
        logout: () => {
            dispatch({ type: actionTypes.UNAUTH_USER });
        }
    };
};

export const LoginPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPanelImpl);
