import React, {Component} from "react";
import {connect} from 'react-redux';
import {changeSocialPageAddress, fetchUserProfile} from '../../actions/Profile/ProfileActions';

import "./Profile.css";

export class ProfileSocialImpl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            pageAddress: this.props.value,
            error: null
        };
    }

    onFocus = (event) => {
        if (!event.target.value || event.target.value.trim().length === 0)
            this.setState({pageAddress: this.props.baseUrl});
    }

    onChange = (event) => {
        this.setState({pageAddress: event.target.value});
    }

    onButtonSaveClicked = () => {
        const {name, baseUrl} = this.props;
        const {pageAddress} = this.state;
        let userPageAddress = pageAddress ? pageAddress.trim() : '';
        if ((userPageAddress.startsWith(baseUrl) && userPageAddress.length > baseUrl.length)
            || userPageAddress.length === 0) {
            this.setState({editMode: false});
            this.props.changeSocialPageAddress(name, pageAddress);
        } else {
            this.setState({error: `Страница должна быть ${baseUrl}`});
        }
    }

    render() {
        const {value, name} = this.props;
        if (this.state.editMode) {
            return (
                <div className="profile__block profile__block_edit-mode">
                    <button
                        className="profile__block__view-mode-button close"
                        onClick={() => this.setState({editMode: false})}>X
                    </button>
                    <span className="profile__field">{name}</span>
                    {this.state.error &&
                        <span className="profile__block__error">
                            {this.state.error}
                        </span>}
                    <input
                        className="profile__block__input"
                        value={this.state.pageAddress}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        placeholder="Введите адрес страницы"/>
                    <button
                        className="profile__block_save-button"
                        onClick={e => this.onButtonSaveClicked(e)}>Сохранить
                    </button>
                </div>
            );
        }

        return (
            <div className="profile__block">
                <span className="profile__field">{name}</span>
                <button
                    className={`profile__action ${value ? 'profile__action_edit' : 'profile__action_add'}`}
                    onClick={() => this.setState({editMode: true})}
                > Изменить
                </button>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.userProfile.profile,
        loading: state.userProfile.loading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserProfile: () => {
            dispatch(fetchUserProfile());
        },
        changeSocialPageAddress: (socialName, pageAddress) => {
            dispatch(changeSocialPageAddress(socialName, pageAddress))
        }
    }
};

export const ProfileSocial = connect(mapStateToProps, mapDispatchToProps)(ProfileSocialImpl);