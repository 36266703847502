import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AuthService } from "../../../services/AuthService";
import { ChannelService } from "../../../services/ChannelService";
import { changeChannel, channelLoaded } from "../../../actions/Channels/ChannelActions";
import { Loader } from "../../Loader";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { isValidUrl } from "../../Banners/EditRedirect/utils";

interface Props {
    channelId: number;
    authService: AuthService;
}

const channelService = new ChannelService();

export const EditChannel = (props: Props) => {
    const history = useHistory();

    if (!props.authService.isAdmin()) {
        history.push("not-found");
    }

    const dispatch = useDispatch();

    const loading = useSelector((state: any) => state.channel.loading);
    const channels = useSelector((state: any) => state.channel.channels);
    let channel = channels.find((c: any) => c.id === props.channelId);
    const [editedChannel, setChannel] = useState(channel);

    useEffect(() => {
        if (!channel) {
            channelService.getChannelsForSubscription().then((loadedChannels) => {
                dispatch(channelLoaded(loadedChannels));
                channel = loadedChannels.find((c: any) => c.id === props.channelId);
                setChannel(channel);
            })
        }

    }, [channels]);


    const saveChannel = async () => {
        const result = await dispatch(changeChannel(editedChannel));
        //eslint-disable-next-line
        if (Boolean(result)) {
            history.push("/channels");
        }
    }

    if (loading) {
        return <Loader visible={true} />
    }

    if (!channel) {
        return <div className="container">
            <h1 className="page-title">Канал не найден</h1>
            <div className="page-section"></div>
        </div>
    }

    return <div className="container">
        <h1 className="page-title">{channel.name}</h1>
        <div className="page-section">
            <Formik enableReinitialize
                initialValues={editedChannel}
                validationSchema={channelValidationSchema}
                onSubmit={() => saveChannel()}>
                {() => (
                    <Form name="editredirect">
                        <div className="section-editor">

                            <label className="section-editor__name-label" htmlFor="channelName">Название Канала</label>
                            <input
                                className={`text-input ${editedChannel.name.trim().length === 0 ? 'text-input_error' : ''}`}
                                id="channelName"
                                type="text"
                                value={editedChannel.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChannel({ ...channel, name: e.target.value })} />

                            <div className="checkbox-wrapper">

                                <input type="checkbox"
                                    id={`channelActive-${editedChannel.id}`}
                                    checked={editedChannel.active}
                                    onChange={() => setChannel({ ...editedChannel, active: !editedChannel.active })} />

                                <label className="redirect-editor__active-label" htmlFor={`channelActive-${channel.id}`} >Активный</label>
                            </div>
                            <label className="section-editor__name-label" htmlFor="channelUrl">Адрес Канала</label>
                            <input
                                className={`text-input ${!isValidUrl(editedChannel.url) ? 'text-input_error' : ''}`}
                                id="channelUrl"
                                type="text"
                                value={editedChannel.url}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChannel({ ...channel, url: e.target.value })} />

                        </div>
                        <div className="wide-save-cancel-controls">
                            <button
                                className="wide-save-cancel-controls__button"
                                type="submit"
                                disabled={editedChannel.name.trim().length === 0 || editedChannel.url.trim().length === 0}
                                onClick={() => saveChannel()}
                            >Сохранить</button>
                            <Link className="wide-save-cancel-controls__button" type="button" to="/">Отмена</Link>
                        </div>
                    </Form>)
                }
            </Formik>
        </div>
    </div>
}

const channelValidationSchema = Yup.object({
    name: Yup.string().transform(e => e.trim()).required("Это поле не может быть пустым"),
    url: Yup.string().required("Это поле не может быть пустым").min(4, "Поле должно содержать не менее 4 символов"),
});
