import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from "formik";
import { SocialLogins } from './SocialLogins';
import { showLoginModalAction, showRegistrationModalAction } from '../../../actions/Modal/ModalActions';
import { signinUser } from '../../../actions/Auth/Auth';
import * as Yup from "yup";
import { TextInput } from '../../Controls';

import './Login.css';

interface Props {
    onLink: () => void;
}

const validationSchema = Yup.object({
    email: Yup.string().transform(e => e.trim()).email("Поле должно содеражать валидный email").required("Это поле не может быть пустым"),
    password: Yup.string().required("Это поле не может быть пустым").min(6, "Поле должно содержать не менее 6 символов"),
});

export const Login = (props: Props) => {

    const dispatch = useDispatch();
    const errorMessage = useSelector((state: any) => state.auth.error);
    const inProcess = useSelector((state: any) => state.auth.inProcess);

    const handleFormSubmit = ({ email, password }: any) => {
        dispatch(signinUser(email.trim(), password));
    }

    const onRegister = () => {
        dispatch(showLoginModalAction(false));
        dispatch(showRegistrationModalAction(true));
        props.onLink();
    }

    const onResetPassword = () => {
        props.onLink();
    }

    return (
        <div className="account-modal-block">
            <h1 className="account-modal-block__title">Вход</h1>
            <Formik enableReinitialize
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleFormSubmit(values)}>
                {(formProps) => (
                    <Form className="registration-block">
                        <Fragment>
                            {errorMessage && errorMessage.length > 0 && (
                                <span className="modal-block__error modal-block__error_summary">{errorMessage}</span>)}
                            <TextInput className='account-modal-block__input text-input text-input_large'
                                name="email"
                                type="text"
                                placeholder="Введите свой E-mail" />

                            <TextInput className='account-modal-block__input text-input text-input_large'
                                name="password"
                                type="password"
                                placeholder="Введите пароль" />

                            <button className="account-modal-block__button"
                                disabled={inProcess || !formProps.isValid}
                                type="submit">{inProcess ? "Вход..." : "Войти"}</button>

                            <div className="account-modal__link-container">
                                <Link to="/registration"
                                    className="account-modal-block__link"
                                    onClick={onRegister}>Зарегистрироваться</Link>
                                <Link
                                    to="/reset-password-request"
                                    className="account-modal-block__link"
                                    onClick={(onResetPassword)}>Забыли пароль?</Link>
                            </div>
                        </Fragment>
                    </Form>)
                }
            </Formik>
            <SocialLogins actionName="Вход" />
        </div>
    )
}
