import React from 'react';
//import { ChannelServiceConsumer } from '../Context/ChannelServiceContext';
import {ChannelService} from '../../services/ChannelService';

const channelService = new ChannelService();

const withChannelService = () => (Wrapped) => {
    return (props) => {
        return (
            <Wrapped {...props} channelService={channelService}/>

        );
    }
};

export {withChannelService};


/*

import React from 'react';
import { ChannelServiceConsumer } from '../Context/ChannelServiceContext';

const withChannelService = () => (Wrapped) => {
  return (props) => {
    return  (
      <ChannelServiceConsumer>
        {
          (channelService) => {
            return (<Wrapped {...props}
              channelService={channelService}/>);
          }
        }
      </ChannelServiceConsumer>
    );
  }
};

export {withChannelService};

*/