import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../actions/Auth/Auth';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from '../../Controls';

import './ResetPassword.css';

const validationSchema = Yup.object({
    email: Yup.string().email("Поле должно содеражать валидный email").required("Это поле не может быть пустым"),
});

export const ResetPassword = () => {

    const dispatch = useDispatch();
    const errorMessage = useSelector((state: any) => {
        return state.resetPass.error;
    });
    errorMessage;
    const handleFormSubmit = (values: any) => {
        dispatch(resetPassword(values.email));
    }

    return (
        <div className="account-modal-block">
            <h1 className="account-modal-block__title">Восстановление пароля</h1>
            <Formik
                enableReinitialize
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleFormSubmit(values)}>
                {
                    <Form className="registration-block">
                        {errorMessage && errorMessage.length > 0 && (
                            <span className="modal-block__error modal-block__error_summary">{errorMessage}</span>)}
                        <TextInput className='account-modal-block__input text-input text-input_large'
                            name="email"
                            type="text"
                            placeholder="Введите свой E-mail" />

                        <button className="account-modal-block__button"
                            type="submit"
                        >Восстановить
                        </button>
                    </Form>
                }
            </Formik>
        </div>
    )
}
