import { BaseService } from './BaseService';

export class ChannelService extends BaseService {
    constructor() {
        super();
        this._baseUrl = "channel";
    }

    async getChannels() {
        return super.get(this._baseUrl);
    }

    async getChannelsForSubscription() {
        return super.get(`${this._baseUrl}/subscribed`);
    }

    async createChannel(url) {
        const body = { url: url };
        const json = JSON.stringify(body);

        return await super.fetch(this._baseUrl, json);
    }

    async changeChannel(channel) {
        const channelRefinement = {
            channelId: channel.id,
            name: channel.name,
            url: channel.url,
            active: channel.active
        }

        const json = JSON.stringify(channelRefinement);

        return await super.fetch(this._baseUrl, json, "PUT");
    }
}