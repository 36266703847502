import { useDispatch } from 'react-redux';
import { GoogleClientId } from '../../../config';
import { externalSigninUser } from '../../../actions/Auth/Auth';
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

export const Google = () => {

    const dispatch = useDispatch();

    const onResponse = async (data: any) => {
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set("Authorization", `Bearer: ${data.access_token}`)
        const json = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.access_token}`, { headers: requestHeaders });
        if (!json) return;
        const body = await json.json();
        dispatch(await externalSigninUser(body.email, body.id, "Google"));

    }

    const login = useGoogleLogin({
        onSuccess: onResponse,
    });

    return (
        <GoogleOAuthProvider clientId={GoogleClientId}>
            <button
                className="social-logins__link social-logins__link_google"
                onClick={() => login()} >Google</button>

        </GoogleOAuthProvider>

    );
}