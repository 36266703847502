export class ViewSelectorService {
    static BIG_MODE = "big-tiles";
    static SMALL_MODE = "small-tiles";
    _key = 'view-type';

    getTilesMode = async () => {
        const viewType = localStorage.getItem(this._key);
        return viewType ? viewType : ViewSelectorService.BIG_MODE;
    }

    setTilesMode = async (type) => {
        localStorage.setItem(this._key, type);
        return new Promise((resolve) => {
            resolve();
        });
    }
}