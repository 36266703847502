import * as actionTypes from '../actions/types';

const initialState = {
    error: null,
    show: false
};

const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_ERROR:
            return {
                ...state,
                error: `${state.error || ''} ${action.payload}`,
                show: true
            };
        case actionTypes.HIDE_ERROR: {
            return {
                ...state,
                error: null,
                show: false
            }
        }
        default:
            return state;
    }
}

export {errorReducer};
