import { useField } from "formik";

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
    name: string;
    className?: string;
    placeholder: string;
    label?: string;
    type: string;
}

export const TextArea = (props: Props) => {

    const [field, meta] = useField(props.name);

    return <div className="account-modal-block__field-wrapper">
        {meta.touched && meta.error && (<span className="modal-block__error">{meta.error}</span>)}
        <textarea {...field} {...props} />
    </div>
}