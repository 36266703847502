import * as actionTypes from '../types';

const showErrorAction = (error: any) => {
    return {
        type: actionTypes.SHOW_ERROR,
        payload: error
    };
};

const hideError = () => {
    return {
        type: actionTypes.HIDE_ERROR
    };
};

export { showErrorAction, hideError };