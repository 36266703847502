import * as actionTypes from '../actions/types';

const initialState = {
    showModal: false
};

export const registrationModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_REGISTRATION_MODAL:
            return {
                showModal: action.payload
            };

        default:
            return state;
    }
}
