import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { BlankImagePattern, MaxDescriptionLength } from "../../config";
import { TextTrimService } from "../../services/TextTrimService";
import { fetchNewsItem } from "../../actions/News/NewsActions";
import moment from "moment";
import { NewsItemModalControls } from "../NewsItemModal/NewsItemModalControls";
import { RecommendedBlock } from "../RecommendedBlock";
import { Loader } from "../Loader";

import "./NewsItemPage.css";

const NewsItemPageImpl = (props: RouteComponentProps) => {

    const textTrimService = new TextTrimService();
    const { idWithSlug } = props.match.params as any;
    const newsId = +idWithSlug.match(/^\d+/)![0];

    const newsList = useSelector((state: any) => state.news.newsList);
    const loading = useSelector((state: any) => state.news.loading);

    const [trimmed, setTrimmed] = useState(true);
    const [news, setNews] = useState<any>(null);


    const dispatch = useDispatch();
    useEffect(() => {


        const selectedNews = newsList.find((it: any) => it.id == newsId);
        if (selectedNews) {
            setNews(selectedNews);
            document.title = selectedNews.title;
            document.querySelector('meta[property="og:title"]')?.setAttribute("content", selectedNews.title);
            document.querySelector('meta[name="description"]')?.setAttribute("content", selectedNews.description);
            document.querySelector('meta[property="og:description"]')?.setAttribute("content", selectedNews.description);
        } else {
            dispatch(fetchNewsItem(newsId));
        }
        return () => {

        }

    }, [newsList, newsId]);

    const getDescription = (description: string) => {
        if (!description)
            return '';

        if (!trimmed)
            return description;

        return textTrimService.trimWithEllipsis(description, MaxDescriptionLength);
    }

    const renderImage = (news: any) => {
        if (!news.imageUrl || news.imageUrl.indexOf(BlankImagePattern) >= 0)
            return null;

        return (<img className="news-modal__image" src={news.imageUrl} alt={news.title} />)
    }

    const onReadMore = () => {
        if (trimmed)
            setTrimmed(false);
    }

    if (!news || loading)
        return <Loader visible={true} />;

    const formattedDate = moment(news.publishDate).format("DD.MM.YYYY HH:mm:ss");

    return <Fragment>
        <div className="news-item-page">
            <div className="news-modal__news-props">
                <time className="news-modal__date">
                    {formattedDate}
                </time>
                <Link
                    className="news-modal__channel"
                    to={`/channels/${news.channelId}-${news.channelName}`}>
                    {news.channelName}</Link>
            </div>
            <h1 className="news-modal__title">{news.title}</h1>
            <div className="news-modal__image-container">
                {renderImage(news)}
            </div>
            <p className="news-modal__description">
                {getDescription(news.description)}
            </p>
            {trimmed && news.description && news.description.length > MaxDescriptionLength &&
                < button onClick={onReadMore} className="news-modal__read-more">
                    Читать дальше
                </button>
            }

            <NewsItemModalControls news={news} path={props.match.path} />

        </div>
        <RecommendedBlock currentNewsId={news.id} />
    </Fragment>

}

export const NewsItemPage = withRouter(NewsItemPageImpl);