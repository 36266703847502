import * as actionTypes from '../actions/types';

const initialState = {
    loading: false,
    profile: null,
    error: null,
    emailChangedSuccess: false,
    emailChangingInFligth: false
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_PROFILE_FETCH:
            return {
                ...initialState,
                loading: true,
                profile: null
            };
        case actionTypes.USER_PROFILE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.payload
            };
        case actionTypes.USER_PROFILE_FETCH_FAILED:
            return {
                ...state,
                loading: false,
                profile: null,
                error: action.payload
            };
        case actionTypes.USER_PROFILE_EMAIL_CHANGE:
            return {
                ...state,
                emailChangedSuccess: false,
                emailChangingInFligth: true
            }
        case actionTypes.USER_PROFILE_PASSWORD_CHANGED_SUCCESS:
            return {
                ...state,
                emailChangedSuccess: true,
                emailChangingInFligth: false
            }
        default:
            return state;
    }
}

export {profileReducer};
