import React, { useEffect } from 'react';
import { fetchFavoriteNews } from '../../actions/Favorites/FavoritesAction';
import { useDispatch } from 'react-redux';
import { NewsPageContainer } from '../containers/NewsPageContainer';
import { NewsPerPage } from '../../config';

export const FavoritesPage = (props) => {

    const dispatch = useDispatch();

    const getNews = (pageNumber) => {
        dispatch(fetchFavoriteNews({ pageNumber, newsPerPage: NewsPerPage }));
    }

    useEffect(() => {
        getNews(0);
    });

    return (<NewsPageContainer {...props} loadItems={getNews} />);
}
