import React, { Component } from 'react';
import { Google } from './Google';
import { Vk } from './Vk';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleClientId } from '../../../config';


export class SocialLogins extends Component {

    render() {
        return (<div className="login-type">
            <span
                className="account-modal-block__title">{this.props.actionName} с помощью <br /> социальных сетей:</span>
            <ul className="social-logins">
                {/* <li className="social-logins__item">
                    <Facebook />
                </li> */}
                <li className="social-logins__item">
                    <Vk />
                </li>
                <li className="social-logins__item">
                    <GoogleOAuthProvider clientId={GoogleClientId}>
                        <Google />
                    </GoogleOAuthProvider>
                </li>
            </ul>
        </div>);
    }
}

