import { Reducer } from "redux";
import { BaseReducer } from "./BaseReducer";
import { TRedirectAction } from "../actions/Redirects/redirect-action-types";
import { RedirectPolicy } from "../domain/redirects/RedirectPolicy";
import * as actionTypes from '../actions/types';

interface IRedirectPolicyState {
    redirects: Array<RedirectPolicy>,
    loading: boolean,
    error: any
}

export class InitialState implements IRedirectPolicyState {
    redirects: Array<RedirectPolicy> = [];
    loading: boolean = true;
    error: any = null;
}

const handlers = {
    [actionTypes.RedirectActionTypes.FETCH_REDIRECTS]: fetchRedirects,
    [actionTypes.RedirectActionTypes.FETCH_REDIRECTS_SUCCESS]: fetchRedirectsSuccess,
    [actionTypes.RedirectActionTypes.FETCH_REDIRECTS_FAILED]: fetchRedirectsFailed,
    [actionTypes.RedirectActionTypes.SAVE_REDIRECT]: saveRedirect,
    [actionTypes.RedirectActionTypes.SAVE_REDIRECT_SUCCESS]: saveRedirectSuccess,
    [actionTypes.RedirectActionTypes.SAVE_REDIRECT_FAILED]: saveRedirectFailed,
};

function fetchRedirects(state: IRedirectPolicyState) {
    return {
        ...state,
        loading: true,
        redirects: []
    }
}

function fetchRedirectsSuccess(state: IRedirectPolicyState, action: TRedirectAction) {
    return {
        ...state,
        loading: false,
        redirects: action.payload
    }
}

function fetchRedirectsFailed(state: IRedirectPolicyState, action: TRedirectAction) {
    return {
        ...state,
        loading: false,
        redirects: [],
        error: action.payload
    }
}

function saveRedirect(state: IRedirectPolicyState) {
    return {
        ...state,
        loading: true,
    }
}

function saveRedirectSuccess(state: IRedirectPolicyState) {
    return {
        ...state,
        loading: false,
    }
}

function saveRedirectFailed(state: IRedirectPolicyState, action: TRedirectAction) {
    return {
        ...state,
        loading: false,
        error: action.payload
    }
}

export const redirectPolicyReducer: Reducer<IRedirectPolicyState, TRedirectAction> = (state: IRedirectPolicyState | undefined = new InitialState(), action: TRedirectAction) => {
    const baseReducer = new BaseReducer(handlers);
    const handler: any = baseReducer.getReducer(action.type)
    return handler ? handler(state, action) : state;
}