import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    SHOW_RESET_PASSWORD_MODAL,
    VERIFY_RESET_PASSWORD_FAILURE,
    VERIFY_RESET_PASSWORD_SUCCESS,
} from '../actions/types/index';

const initialState = {
    showModal: false
};

export const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_RESET_PASSWORD_MODAL:
            return {
                showModal: action.payload
            };
        case RESET_PASSWORD_SUCCESS:
            return {...state, resetPassword: true, error: null};
        case RESET_PASSWORD_FAILURE:
            return {...state, resetPassword: false, error: action.payload};
        case VERIFY_RESET_PASSWORD_SUCCESS:
            return {...state, verifyResetPassword: true, error: {}, resetPassword: false};
        case VERIFY_RESET_PASSWORD_FAILURE:
            return {...state, verifyResetPassword: false, error: {verifyResetPassword: action.payload}};
        default:
            return state;
    }
}