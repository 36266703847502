import * as actionTypes from '../types';
import {AuthService} from '../../services/AuthService';
import {showLoginModalAction, showRegistrationModalAction} from '../../actions/Modal/ModalActions';
import {fetchChannels} from "../../actions/Channels/SliderChannelActions";
import {fetchNews} from '../../actions/News/NewsActions';
import {showErrorAction} from '../Error/ErrorAction';
import {NewsPerPage} from '../../config';
import {fetchFavoriteIds} from '../Favorites/FavoritesAction';
import {
    AUTH_USER,
    EMAIL_CONFIRMATION,
    EMAIL_CONFIRMATION_RESENT_INFLIGTH,
    EMAIL_CONFIRMATION_RESENT_SUCCESS,
    EMAIL_CONFIRMATION_SENT,
    RESET_PASSWORD_FAILURE,
    SIGNIN_FAILURE,
    SIGNUP_FAILURE,
    SIGNUP_INFLIGTH,
    SIGNUP_SUCCESS,
    UNAUTH_USER,
} from '../types/index';
//import { showModal } from '../Login/LoginModal';

const authService = new AuthService();

const doPostSignin = (dispatch, userName) => {
    dispatch(showLoginModalAction(false));
    dispatch({type: AUTH_USER, payload: userName});
    dispatch(fetchChannels(() => {
    }));
    dispatch(fetchNews(0, NewsPerPage, undefined));
    dispatch(fetchFavoriteIds());
}

/**
 * Error helper
 */
export function authError(CONST, error) {
    return {
        type: CONST,
        payload: error
    };
}

/**
 * Sign up
 */
export function signupUser(email, password, confirmPassword) {
    return async (dispatch) => {

        if (!validateEmail(email)) {
            dispatch(authError(SIGNUP_FAILURE, "Поле email невалидно"));
            return;
        }

        if (password.length < 6) {
            dispatch(authError(SIGNUP_FAILURE, "Пароль менее 6 символов"));
            return;
        }
        if (password !== confirmPassword) {
            dispatch(authError(SIGNUP_FAILURE, "Пароли не совпадают"));
            return;
        }
        try {
            dispatch({type: SIGNUP_INFLIGTH});
            await authService.register(email, password, confirmPassword);
            dispatch({type: SIGNUP_SUCCESS});
            dispatch({type: EMAIL_CONFIRMATION});
        } catch (error) {
            const message = error.message;
            dispatch({type: SIGNUP_FAILURE, payload: message});
        }
    };
}

export function signupSuccessApproved() {
    return (dispatch) => {
        dispatch(showRegistrationModalAction(false));
    };
}

/**
 * Sign in
 */
export function signinUser(email, password) {
    return async (dispatch) => {
        if (email.length === 0) {
            dispatch(authError(SIGNIN_FAILURE, "Email не может быть пустым"));
            return;
        }

        if (!validateEmail(email)) {
            dispatch(authError(SIGNUP_FAILURE, "Поле email невалидно"));
            return;
        }

        if (password.length <= 6) {
            dispatch(authError(SIGNIN_FAILURE, "Пароль не может быть меньше 6 символов"));
            return;
        }

        dispatch({type: SIGNUP_INFLIGTH});
        try {
            const userName = await authService.login(email, password);
            doPostSignin(dispatch, userName);
        } catch (error) {
            dispatch(authError(SIGNIN_FAILURE, error.message.replaceAll("\"", "")));
        }
    };
}

export function externalSigninUser(email, token, provider) {
    return async (dispatch) => {
        try {
            const userName = await authService.externalLogin(email, token, provider);
            doPostSignin(dispatch, userName);
        } catch (error) {
            dispatch(authError(SIGNIN_FAILURE, `${provider} отклонил авторизацию`));
        }
    }
}

export function confirmEmail(email, token) {
    return async (dispatch) => {
        try {
            const result = await authService.confirmEmail(email, token);
            if (result)
                dispatch({type: EMAIL_CONFIRMATION_SENT});
            else
                dispatch(emailConfirmationError());
        } catch (error) {
            dispatch(showErrorAction(error));
            dispatch(emailConfirmationError());
        }
    };
}

export const emailConfirmation = (inProcess) => {
    return {
        type: actionTypes.EMAIL_CONFIRMATION,
        payload: inProcess
    };
};

export const emailConfirmationError = () => {
    return {
        type: actionTypes.EMAIL_CONFIRMATION_ERROR
    };
};

/**
 * Resend verification code
 */
export function resendVerification(email) {
    return async (dispatch) => {
        try {
            dispatch({type: EMAIL_CONFIRMATION_RESENT_INFLIGTH});
            const result = await authService.resendVerification(email);
            if (result) {
                dispatch({type: EMAIL_CONFIRMATION_RESENT_SUCCESS});
            }
        } catch (error) {
            dispatch(showErrorAction("Не удалось подтвердить email"));
        }
    };
}

/**
 * Verify email
 */
export function verifyEmail() {
    return function () {
        // axios.post(`${API_URL}/signup/verify-email`, props)
        //   .then(response => {
        //     localStorage.setItem('user', JSON.stringify(response.data));
        //     dispatch({ type: AUTH_USER });
        //     browserHistory.push('/reduxauth/users');
        //   })
        //   .catch(response => dispatch(authError(VERIFY_EMAIL_ERROR, response.data.error)));
    };
}

/**
 * Sign out
 */
export function signoutUser() {
    localStorage.clear();

    return {
        type: UNAUTH_USER
    };
}

export function resetPassword(email) {
    return async (dispatch) => {
        if (email.trim().length === 0) {
            dispatch(authError(RESET_PASSWORD_FAILURE, "Поле email не может быть пустым"));
            return;
        }
        if (!validateEmail(email)) {
            dispatch(authError(RESET_PASSWORD_FAILURE, "Поле email невалидно"));
            return;
        }
        try {
            await authService.resetPassword(email);
            window.location = "/password-reset-sent";
        } catch (error) {
            dispatch(authError(RESET_PASSWORD_FAILURE, error.message));
        }
    }
}

export function changePassword(email, password, token) {
    return async (dispatch) => {

        if (!validateEmail(email)) {
            dispatch(authError(SIGNUP_FAILURE, "Поле email невалидно"));
            return;
        }

        if (password.length < 6) {
            dispatch(authError(SIGNUP_FAILURE, "Пароль менее 6 символов"));
            return;
        }

        try {
            dispatch({type: SIGNUP_INFLIGTH});
            await authService.resetPasswordByToken(email, password, token);
            window.location = '/login';
        } catch (error) {
            const message = error.message;
            dispatch({type: SIGNUP_FAILURE, payload: message});
        }
    };
}


function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}