import * as actionTypes from "../types";
import { ChannelService } from "../../services/ChannelService";
import { showErrorAction } from '../Error/ErrorAction';

const sliderChannelRequested = () => {
    return {
        type: actionTypes.SLIDER_CHANNEL_REQUESTED
    };
};

const sliderChannelFetched = channels => {
    return {
        type: actionTypes.SLIDER_CHANNEL_FETCHED,
        payload: channels
    };
};

const sliderChannelFailture = error => {
    return {
        type: actionTypes.SLIDER_CHANNEL_FAILTURE,
        payload: error
    };
};

const sliderChannelSelected = channelId => {
    return {
        type: actionTypes.SLIDER_CHANNEL_SELECTED,
        payload: channelId
    };
};

const fetchChannels = () => {
    return async (dispatch, postAction) => {
        dispatch(sliderChannelRequested());
        const channelService = new ChannelService();
        try {
            const channels = await channelService
                .getChannels();

            let activeChannels = channels.filter(c => c.active);

            activeChannels = updateChannels(activeChannels);
            dispatch(sliderChannelFetched(activeChannels));
            postAction(activeChannels);
        } catch (error) {
            dispatch(showErrorAction(error));
        }
    };
};

const updateChannels = channels => {
    channels = [
        { name: "Избранное", id: -2, url: "/favorites" },
        { name: "Весь дайджест", id: -1, url: "/" },
        ...channels
    ];

    return channels;
};

export {
    sliderChannelRequested,
    sliderChannelFetched,
    sliderChannelFailture,
    sliderChannelSelected,
    fetchChannels
};
