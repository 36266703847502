import * as Config from '../config';

export class TilesService {
    static wideTile = false;

    getNewsItemClassList = (newsItem, index) => {
        const { BlankImagePattern, NewsPerPage } = Config;
        const hasImage = newsItem.imageUrl && newsItem.imageUrl.indexOf(BlankImagePattern) === -1;
        let classList = `news-item ${hasImage ? 'news-item__pictured' : 'news-item__blank'}`;

        if (this.isWideTilePosition(index) || TilesService.wideTile) {
            if (newsItem.imageUrl) {
                classList += ' news-item_wide';
                TilesService.wideTile = false;
            } else
                TilesService.wideTile = true;
        }

        if (index === NewsPerPage - 1)
            TilesService.wideTile = false;

        return classList;
    }

    isWideTilePosition(index) {
        const { wideTilesPosition, NewsPerPage } = Config;
        return wideTilesPosition.indexOf(index % NewsPerPage) !== -1;
    }
}