import * as yup from 'yup';
import { RedirectPolicy } from '../../../domain/redirects/RedirectPolicy';

export const createEmpryRedirect = (): any => {
    return {
        id: 0,
        name: "",
        links: [
            { id: -1, active: true, url: "", type: "Direct" }
        ],
        sources: [{ id: -1, url: "" }]
    }
}

export const isValidUrl = (url: string): boolean => {
    const urlRegExp = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]?\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm;
    const result = urlRegExp.test(url);
    return result;
}

export const isValidReferer = (referer: string): boolean => {
    const transformedReferer = referer?.toLowerCase();
    if (transformedReferer === "all" || transformedReferer === "none")
        return true;

    return isValidUrl(referer);
}

export const isValidRedirect = (redirect: RedirectPolicy): boolean => {
    try {
        const result = redirectValidationSchema.validateSync(redirect);
        return Boolean(result);
    }
    catch (error) {
        return false;
    }
}

export const redirectValidationSchema = yup.object().shape({
    name: yup.string().required(),
    links: yup.array(
        yup.object().shape({
            id: yup.number().required(),
            active: yup.boolean().required(),
            url: yup.string().required().test(x => isValidUrl(x!)),
        })
    ).min(1).required(),
    source: yup.string().required().test(x => isValidReferer(x!))
});