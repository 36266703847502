import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withChannelService } from '../../Hoc/WithChannelService';
import { Loader } from '../../Loader';
import { ChannelItem } from '../ChannelItem';
import { ChannelSubscription } from '../ChannelSubscription';
import { ChannelItemByPage } from '../../../config';
import {
    fetchChannelForSubscription,
    changePage,
    changeChannelSubscription,
} from '../../../actions/Channels';
import { ChannelFiterManager } from '../../../domain';

import './ChannelList.css';

const channelFiterManager = new ChannelFiterManager();

class ChannelListImpl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changedChannels: []
        }
    }

    componentDidMount() {
        this.props.loadChannels();
    }

    onPageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * ChannelItemByPage);
        this.props.changePage(offset);
        window.scrollTo(0, 0);
    }

    onButtonMoreClick = () => {
        this.props.changePage(this.props.pageOffset + 1);
    }

    onSubscriptionChanged = (channelStatus) => {
        this.onChannelSubscribeClicked(channelStatus.id);
        let { channels } = this.props;
        const channel = channels.filter(c => c.id === channelStatus.id)[0];
        if (channel.subscribed !== channelStatus.subscribed)
            this.state.changedChannels.push({ id: channelStatus.id, subscribed: channelStatus.subscribed });
    }

    onChannelSubscribeClicked = (id) => {
        this.props.subscriptionChanged(id);
    }

    getChannelsCount = () => {
        const { channels, filter } = this.props;

        const channelCount = !filter
            ? channels.length
            : channels.filter(it => this.isChannelMatchFilter(it, filter)).length

        return Math.ceil(channelCount / ChannelItemByPage);
    }

    renderButtonMore = () => {
        const { channels, filter, pageOffset } = this.props;
        let filteredChannels = filter
            ? channels.filter(channel => channelFiterManager.isChannelMatchFilter(channel, filter))
            : channels;

        if ((pageOffset + 1) * ChannelItemByPage > filteredChannels.length)
            return <div className="channel-no-more"></div>;

        return (<button
            onClick={this.onButtonMoreClick}
            className="channel-modal__button-more">
            Еще
        </button>)
    }

    renderChannels = () => {
        const { channels, filter, pageOffset } = this.props;
        const itemByPage = ChannelItemByPage;
        let filteredChannels = filter
            ? channels.filter(channel => channelFiterManager.isChannelMatchFilter(channel, filter))
            : channels;

        filteredChannels = filteredChannels.slice(0, (pageOffset + 1) * itemByPage);
        return filteredChannels.map((channel) =>
            <ChannelItem key={`channel-${channel.id}`}
                channel={channel}
                onChange={(channelStatus) => this.onSubscriptionChanged(channelStatus)}
                onSubscriptionChanged={(id) => this.onChannelSubscribeClicked(id)} />
        )
    }

    render() {
        const { validating, loading, error } = this.props;

        if (validating) {
            return (
                <div>
                    <p className='channel-list-validation'> Происходит валидация канала и загрузка новостей</p>
                    <Loader visible={validating} />
                </div>
            )
        }

        if (error) {
            return (<div><p className='channel-list-validation'> {error.message} </p></div>)
        }

        if (loading) {
            return <Loader visible={loading} />
        }

        return (
            <React.Fragment>
                {this.renderChannels()}
                {this.renderButtonMore()}
                <ChannelSubscription />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        channels: state.subscriber.channels,
        loading: state.subscriber.loading,
        error: state.subscriber.error,
        filter: state.subscriber.filter,
        validating: state.subscriber.validating,
        pageOffset: state.subscriber.offset,
        changedChannels: state.subscriber.changedChannels
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        loadChannels: () => {
            dispatch(fetchChannelForSubscription());
        },
        subscriptionChanged: (id) => {
            dispatch(changeChannelSubscription(id));
        },
        changePage: (offset) => {
            dispatch(changePage(offset))
        }
    }
};

export const ChannelList = withChannelService()(connect(mapStateToProps, mapDispatchToProps)(ChannelListImpl));