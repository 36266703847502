import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RedirectPolicy } from "../../../domain/redirects/RedirectPolicy";
import { Loader } from "../../Loader";
import { fetchRedirectList, removeRedirect } from "../../../actions/Redirects/redirect-action";

import "./RedirectList.css";

export const RedirectList = () => {
    const dispatch: any = useDispatch();
    const loading = useSelector((state: any) => state.redirectPolicy?.loading);
    const redirects = useSelector((state: any) => state.redirectPolicy?.redirects);

    useEffect(() => {
        dispatch(fetchRedirectList())
    }, []);

    const removeRedirectItem = (redirectId: number) => {
        dispatch(removeRedirect(redirectId));
    }

    const renderRedirectItem = (redirect: RedirectPolicy) => {
        return <tr key={redirect.id}>
            <td><Link to={`/redirects/edit/${redirect.id}`}>{redirect.name}</Link> </td>
            <td>{redirect.source}</td>
            <td>
                <span title="Активных" style={{ fontWeight: 700 }} > {redirect.links.filter(l => l.active).length}</span> &nbsp;
                / &nbsp;
                <span title="Всего"> {redirect.links.length} </span>
            </td>
            <td>
                <button className="button-link" type="button" onClick={() => removeRedirectItem(redirect.id)}>Удалить</button>
            </td>
        </tr>
    }

    const renderRedirects = () => {
        if (redirects.length === 0) {
            return <div>
                <h1 className="nothing-found-caption">
                    Ничего не найдено
                </h1>
            </div >
        }
        return <table className="table">
            <thead>
                <tr>
                    <th>Название</th>
                    <th>Источник</th>
                    <th>Количество ссылок (Активных/Всего)</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {redirects.map((it: RedirectPolicy) => renderRedirectItem(it))}
            </tbody>
        </table>
    }

    if (loading) {
        return <Loader visible={true} />
    }

    return <div className="container">
        <Link className="page-title" to="/redirects">Редиректы</Link>
        <div className="redirects-container page-section">
            <div className="redirects-container__add-new-wrapper">
                <Link to="/redirects/edit" className="redirects-container__add-new-redirect" title="Создать новый редирект">
                    +
                </Link>
            </div>
            {renderRedirects()}
        </div>
    </div>
}