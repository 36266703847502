import * as actionTypes from '../actions/types';

import {
    AUTH_USER,
    EMAIL_CONFIRMATION_RESENT_INFLIGTH,
    EMAIL_CONFIRMATION_RESENT_SUCCESS,
    SHOW_LOGIN_MODAL,
    SHOW_REGISTRATION_MODAL,
    SIGNIN_FAILURE,
    SIGNUP_FAILURE,
    SIGNUP_INFLIGTH,
    SIGNUP_RESEND_FAILURE,
    SIGNUP_SUCCESS,
    UNAUTH_USER,
    VERIFY_EMAIL_ERROR
} from '../actions/types/index';

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case SIGNUP_SUCCESS:
            return { ...state, signup: true, inProcess: false, error: null };
        case SIGNUP_INFLIGTH:
            return { ...state, signup: false, inProcess: true, error: null };
        case SIGNUP_FAILURE:
            return { ...state, signup: false, error: action.payload, inProcess: false };
        case SIGNUP_RESEND_FAILURE:
            return { ...state, signup: true, error: action.payload, inProcess: false };
        case VERIFY_EMAIL_ERROR:
            return { ...state, signup: true, error: action.payload, inProcess: false };
        case SIGNIN_FAILURE:
            return { ...state, signup: false, error: action.payload, inProcess: false };
        case AUTH_USER:
            return {
                ...state,
                authenticated: true,
                error: null,
                userName: action.payload,
                confirmationInProcess: false,
                emailSent: false
            };
        case UNAUTH_USER:
            return { ...state, authenticated: false, error: null };
        case actionTypes.EMAIL_CONFIRMATION:
            return { ...state, authenticated: false, confirmationInProcess: action.payload };
        case actionTypes.EMAIL_CONFIRMATION_SENT:
            return { ...state, authenticated: false, confirmationInProcess: false, emailSent: true, error: null };
        case EMAIL_CONFIRMATION_RESENT_SUCCESS:
            return { ...state, authenticated: false, emailSent: true, confirmationInProcess: false, error: null }
        case EMAIL_CONFIRMATION_RESENT_INFLIGTH:
            return { ...state, authenticated: false, emailSent: false, confirmationInProcess: true, error: null }
        case actionTypes.EMAIL_CONFIRMATION_ERROR:
            return {
                ...state,
                authenticated: false,
                emailConfimation: false,
                confirmationInProcess: false,
                inProcess: false,
                error: 'Возникли проблемы при подтверждении электронной почты' //resend verification code
            }
        case SHOW_LOGIN_MODAL:
        case SHOW_REGISTRATION_MODAL:
            return {
                ...state,
                error: null
            }
        case actionTypes.HIDE_LOGIN_MODAL:
            return {
                ...state,
                inProcess: false
            }
        default:
            return state;
    }
}