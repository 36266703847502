import { BaseService } from "./BaseService";

export class SubscriberService extends BaseService {

    subscribe = async (channelId: number) => {
        return await super.fetch(`subscription/${channelId}`);
    }

    unsubscribe = async (channelId: number) => {
        return await super.fetch(`subscription/${channelId}`, {}, "DELETE");
    }
}