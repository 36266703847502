import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenericModal } from "../../GenericModal";
import { ChannelPage } from "../../pages/ChannelPage";
import { showChannelModalAction } from '../../../actions/Modal/ModalActions';

import './ChannelModal.css';

const channelModalAddress = '/channels';

export const ChannelModal = () => {
    const dispatch = useDispatch();
    const showModal = useSelector((state: any) => state.channelModal.showModal);
    const [originalAddress, setOriginalAddress] = useState('');

    useEffect(() => {
        setOriginalAddress(window.location.pathname);
    }, []);


    const onOpen = () => {
        const address = !window.location.pathname.startsWith("/item")
            ? window.location.pathname
            : "/";

        setOriginalAddress(address);

        if (window.location.pathname !== channelModalAddress)
            window.history.pushState({}, '', channelModalAddress);
    }

    const onClose = () => {
        dispatch(showChannelModalAction(false));
        if (!originalAddress || originalAddress === channelModalAddress)
            window.history.pushState({}, '', '');

        else if (window.location.pathname !== originalAddress)
            window.history.pushState({}, '', originalAddress);
    }

    return (
        <GenericModal className="channel-modal"
            isOpen={showModal}
            onOpen={onOpen}
            onClose={onClose}
            additionalClasses="modal-dialog_full-screen channel-modal__dialog"
        >
            <ChannelPage />
        </GenericModal>

    );
}