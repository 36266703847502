export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const SIGNUP_INFLIGTH = 'SIGNUP_INFLIGTH';
export const SIGNUP_RESEND_FAILURE = 'SIGNUP_RESEND_FAILURE';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';
export const EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION';
export const EMAIL_CONFIRMATION_SENT = 'EMAIL_CONFIRMATION_SENT';
export const EMAIL_CONFIRMATION_RESENT = 'EMAIL_CONFIRMATION_RESENT';
export const EMAIL_CONFIRMATION_ERROR = 'EMAIL_CONFIRMATION_ERROR';
export const EMAIL_CONFIRMATION_RESENT_INFLIGTH = 'EMAIL_CONFIRMATION_RESENT_INFLIGTH';
export const EMAIL_CONFIRMATION_RESENT_SUCCESS = 'EMAIL_CONFIRMATION_RESENT_SUCCESS';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_ERROR';
export const VERIFY_RESET_PASSWORD_SUCCESS = 'VERIFY_RESET_PASSWORD_SUCCESS';
export const VERIFY_RESET_PASSWORD_FAILURE = 'VERIFY_RESET_PASSWORD_FAILURE';

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';

export const FETCH_USERS = 'FETCH_USERS';

export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';

export const SHOW_REGISTRATION_MODAL = 'SHOW_REGISTRATION_MODAL';

export const SHOW_RESET_PASSWORD_MODAL = 'SHOW_RESET_PASSWORD_MODAL';

export const CHANNEL_LOADED = 'CHANNEL_LOADED';

export const SHOW_CHANNEL_MODAL = 'SHOW_CHANNEL_MODAL';

export const NEWS_LOADED = 'NEWS_LOADED';
export const NEWS_ITEM_LOADED = 'NEWS_ITEM_LOADED';
export const NEWS_ITEM_SELECTED = 'NEWS_ITEM_SELECTED';
export const NEWS_CLEAN = 'NEWS_CLEAN';
export const NEWS_CHANNEL_CHANGED = 'NEWS_CHANNEL_CHANGED';
export const MODAL_NEWS_HIDE = 'MODAL_NEWS_HIDE';
export const NEWS_RELOADED = 'NEWS_RELOADED';

export const USER_PROFILE_FETCH = 'USER_PROFILE_FETCH';
export const USER_PROFILE_FETCH_SUCCESS = 'USER_PROFILE_FETCH_SUCCESS';
export const USER_PROFILE_FETCH_FAILED = 'USER_PROFILE_FETCH_FAILED';
export const USER_PROFILE_PASSWORD_CHANGED_SUCCESS = 'USER_PROFILE_PASSWORD_CHANGED_SUCCESS';
export const USER_PROFILE_EMAIL_CHANGE = 'USER_PROFILE_EMAIL_CHANGE';
export const USER_PROFILE_EMAIL_CHANGE_SUCCESS = 'USER_PROFILE_EMAIL_CHANGE_SUCCESS';

export const VIEW_SELECTOR_MODE_CHANGED = 'VIEW_SELECTOR_MODE_CHANGED';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const SLIDER_CHANNEL_REQUESTED = 'SLIDER_CHANNEL_REQUESTED';
export const SLIDER_CHANNEL_FETCHED = 'SLIDER_CHANNEL_FETCHED';
export const SLIDER_CHANNEL_SELECTED = 'SLIDER_CHANNEL_SELECTED';
export const SLIDER_CHANNEL_FAILTURE = 'SLIDER_CHANNEL_FAILTURE';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const FAVORITES_LOADED = 'FAVORITES_LOADED';
export const FAVORITES_ADD = 'FAVORITES_ADD';
export const FAVORITES_REMOVE = 'FAVORITES_REMOVE';

export enum FeedbackActionTypes {
    FEEDBACK_SEND = 'FEEDBACK_SEND',
    FEEDBACK_SEND_SUCCESS = 'FEEDBACK_SEND_SUCCESS',
    FEEDBACK_SEND_FAILED = 'FEEDBACK_SEND_FAILED',
    FEEDBACK_SEND_APPROVE = 'FEEDBACK_SEND_APPROVE'
}


export enum RedirectActionTypes {
    FETCH_REDIRECTS = 'FETCH_REDIRECTS',
    FETCH_REDIRECTS_SUCCESS = 'FETCH_REDIRECTS_SUCCESS',
    FETCH_REDIRECTS_FAILED = 'FETCH_REDIRECTS_FAILED',
    SAVE_REDIRECT = 'SAVE_REDIRECT',
    SAVE_REDIRECT_SUCCESS = 'SAVE_REDIRECT_SUCCESS',
    SAVE_REDIRECT_FAILED = 'SAVE_REDIRECT_FAILED',
}

export enum ChannelAction {
    CHANNEL_CHANGE = 'CHANNEL_CHANGE',
    CHANNEL_CHANGE_SUCCESS = 'CHANNEL_CHANGE_SUCESS',
    CHANNEL_CHANGE_FAILED = 'CHANNEL_CHANGE_FAILED',
}

export enum SubscribtionAction {
    LOAD_CHANNEL = 'LOAD_CHANNEL',
    LOAD_CHANNEL_SUCCESS = 'LOAD_CHANNEL_SUCCESS',
    LOAD_CHANNEL_FAILED = 'LOAD_CHANNEL_FAILED',
    CHANNEL_FILTER_CHANGED = 'CHANNEL_FILTER_CHANGED',
    SUBSCRIPTION_PAGE_CHANGED = 'SUBSCRIPTION_PAGE_CHANGED',
    CHANNEL_SUBSCRIPTION_CHANGED = 'CHANNEL_SUBSCRIPTION_CHANGED',
    CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
    CHANNEL_VALIDATING = 'CHANNEL_VALIDATING',
    CHANNEL_ADDED = 'CHANNEL_ADDED'
}