import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addChannelByUrl, changeFilter } from '../../../actions/Channels';
import { ChannelFiterManager } from "../../../domain";

import './ChannelSearch.css';

export const ChannelSearch = () => {

    const channelFilterManager = new ChannelFiterManager();

    const [url, setUrl] = useState('');
    const dispatch = useDispatch();
    const channels = useSelector((state: any) => state.subscriber.channels);
    const filter = useSelector((state: any) => state.subscriber.filter);

    const onFilterByPattern = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeFilter({ ...filter, pattern: event.target.value }));
        setUrl(event.target.value);
    }

    const isChannelInList = () => {
        return channels.some((c: any) => channelFilterManager.isChannelMatchFilter(c, filter));
    }

    const searchChannel = async (event: any) => {
        event.preventDefault();
        if (url.trim().length > 0 && !isChannelInList()) {
            dispatch(addChannelByUrl(url));
        }
    }

    return (<form className="channel-search" onSubmit={searchChannel} >
        <input
            className="channel-search__url"
            type="text"
            onChange={onFilterByPattern}
            placeholder="Введите адрес рсс\подкаста\телеграм канала или выберите источник из списка ниже" />
        <button className="channel-search__button" onClick={searchChannel} > Добавить </button>
    </form>)

}