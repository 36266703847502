import * as actionTypes from '../types';
import {NewsService} from '../../services/NewsService';
import {SearchService} from '../../services/SearchService';
import {showErrorAction} from '../Error/ErrorAction';
import {AuthService} from '../../services/AuthService';

const newsService = new NewsService();
const searchService = new SearchService();

const newsLoaded = (newsList) => {
    return {
        type: actionTypes.NEWS_LOADED,
        payload: newsList,
    };
};

const newsReloaded = (newsList) => {
    return {
        type: actionTypes.NEWS_RELOADED,
        payload: newsList
    }
}

const newsItemLoaded = (newsItem) => {
    return {
        type: actionTypes.NEWS_ITEM_LOADED,
        payload: newsItem,
    };
};

const newsItemSelected = (index) => {
    return {
        type: actionTypes.NEWS_ITEM_SELECTED,
        payload: index,
    };
};

const newsChannelChanged = (channelId) => {
    return {
        type: actionTypes.NEWS_CHANNEL_CHANGED,
        payload: channelId
    }
}

const newsModalHide = () => {
    return {
        type: actionTypes.MODAL_NEWS_HIDE,
    };
};

const clearNewList = () => {
    return {
        type: actionTypes.NEWS_CLEAN
    };
};

const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER
    };
};

const hideLoader = () => {
    return {
        type: actionTypes.HIDE_LOADER
    };
}

const fetchNews = (pageNumber, newsPerPage, channelId) => {
    return async (dispatch) => {
        if (pageNumber === 0)
            dispatch(clearNewList());

        dispatch(showLoader());
        try {
            const newsList = await newsService
                .getNews({
                    pageNumber,
                    newsPerPage,
                    channelId: channelId ? channelId : ""
                });

            if (pageNumber === 0)
                dispatch(newsReloaded(newsList));
            else
                dispatch(newsLoaded(newsList));
        } catch (error) {
            dispatch(hideLoader());
            dispatch(showErrorAction(error));
        }
    };
};

const fetchNewsItem = (id) => {
    return async (dispatch) => {
        try {
            const newsItem = await newsService
                .getNewsItem(id);

            dispatch(newsItemLoaded(newsItem));
            dispatch(newsItemSelected(null));
        } catch (error) {
            dispatch(showErrorAction(error));
        }
    };
}

const searchNews = (request) => {
    return async (dispatch) => {
        if (request.pageNumber === 0)
            dispatch(clearNewList());

        dispatch(showLoader(true));
        try {
            request.channelId = request.channelId || '';
            const searchNewsList = await searchService.findNews(request);
            if (request.pageNumber === 0)
                dispatch(newsReloaded(searchNewsList));
            else
                dispatch(newsLoaded(searchNewsList));
        } catch (error) {
            dispatch(showLoader(false));
            dispatch(showErrorAction(error));
        }
    }
}

const fetchFavoriteNews = (request) => {
    return async (dispatch) => {
        const authService = new AuthService();
        if (!authService.isAuthenticated())
            window.location = '/login';

        if (request.pageNumber === 0)
            dispatch(clearNewList());

        dispatch(showLoader(true));

        try {
            const favoriteNews = await newsService.getFavoriteNews(request);
            if (request.pageNumber === 0)
                dispatch(newsReloaded(favoriteNews));
            else
                dispatch(newsLoaded(favoriteNews));
        } catch (error) {
            dispatch(showLoader(false));
            dispatch(showErrorAction(error));
        }

    }
}

export {
    newsLoaded,
    newsReloaded,
    newsItemSelected,
    newsChannelChanged,
    newsModalHide,
    newsItemLoaded,
    clearNewList,
    showLoader,
    hideLoader,
    fetchNews,
    fetchNewsItem,
    searchNews,
    fetchFavoriteNews
};