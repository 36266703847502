import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmEmail, resendVerification } from '../../../actions/Auth/Auth';

import './Confirmation.css';

class ConfirmationImpl extends Component {

    componentDidMount() {
        const email = this.props.match.params.email;
        const params = new URLSearchParams(this.props.location.search);
        const code = params.get('code');
        this.props.confirmEmail(email, code);
    }

    resendVerification = () => {
        const email = this.props.match.params.email;
        this.props.resendVerification(email);
    }

    onLoginClick = () => {
        window.history.pushState({}, '', '');
    }

    render() {
        const { inProcess, error, emailSent } = this.props;
        if (inProcess) {
            return (<p className="confirmation__message"> Происходит валидация вашего адреса электронной почты</p>);
        }

        if (error && error.length > 0) {
            return (<React.Fragment>
                <p className="confirmation__error">{error}</p>
                <button className="confirmation__button"
                    onClick={() => this.resendVerification()}
                >Выслать подтверждение еще раз
                </button>
            </React.Fragment>);
        }
        if (!inProcess && emailSent) {
            return (<div className="confirmation__email-sent">
                <h1 className="confirmation__title">Спасибо за регистрацию</h1>
                <Link
                    className="confirmation__link"
                    to="/login"
                    onClick={(e) => this.onLoginClick(e)}>Вход</Link></div>);
        }

        return (
            <p className="confirmation__message"> Происходит валидация вашего адреса электронной почты</p>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        inProcess: state.auth.confirmationInProcess,
        error: state.auth.error,
        emailSent: state.auth.emailSent
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        confirmEmail: (email, token) => {
            dispatch(confirmEmail(email, token));
        },
        resendVerification: (email) => {
            dispatch(resendVerification(email));
        }
    };
};

export const Confirmation = (connect(mapStateToProps, mapDispatchToProps)(ConfirmationImpl));
