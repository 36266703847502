import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { redirectValidationSchema, createEmpryRedirect, isValidRedirect, isValidReferer } from "./utils";
import { Form, Formik } from "formik";
import { RedirectLinkEditor } from "./RedirectLinkEditor";
import { RedirectPolicy } from "../../../domain/redirects/RedirectPolicy";
import { RedirectLink } from "../../../domain/redirects/RedirectLink";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Loader } from "../../Loader";
import { fetchRedirectList, saveRedirect } from "../../../actions/Redirects/redirect-action";

import "./EditRedirect.css";

interface Props extends RouteComponentProps<{ id?: string }> {
}

interface State {
    redirectId?: number;
    redirect: RedirectPolicy;
    error?: string;
}

type TEditRedirectProps = Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const defaultTargetRedirectType = "Direct";

class EditRedirectImpl extends Component<TEditRedirectProps, State>
{
    state: State = {
        redirect: createEmpryRedirect(),
        redirectId: undefined,
        error: undefined
    }

    constructor(props: TEditRedirectProps) {
        super(props);

        this.changeRedirectLink = this.changeRedirectLink.bind(this);
        this.removeRedirectLink = this.removeRedirectLink.bind(this);
        this.addNewRedirectLink = this.addNewRedirectLink.bind(this);
    }

    componentDidMount(): void {
        const redirectIdParam = this.props.match.params?.id;
        if (redirectIdParam) {
            const redirectId = Number(redirectIdParam);
            const redirect = this.findredirectById(redirectId);
            if (redirect) {
                this.setState({ redirect: redirect, redirectId: redirectId });
            }
            else {
                this.setState({ redirectId: redirectId });
                this.props.fetchredirectList();
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<TEditRedirectProps>): void {
        if (prevProps.redirects !== this.props.redirects) {
            const redirect = this.findredirectById(this.state.redirectId!);
            if (redirect) {
                this.setState({ redirect: redirect });
            }
            else if (prevProps.loading && !this.props.loading) {
                this.setState({ error: "Редирект не найден" });
            }
        }
    }

    findredirectById(redirectId: number) {
        const redirect = this.props.redirects.find((b: RedirectPolicy) => b.id === redirectId)
        return redirect;
    }

    async saveredirect(redirect: RedirectPolicy) {
        if (this.state.redirect) {
            const result = await this.props.saveredirect(redirect);
            if (result) {
                this.props.history.push("/redirects");
            }
        }
    }

    removeRedirectLink(redirectLink: RedirectLink) {
        this.setState({ redirect: { ...this.state.redirect, links: this.state.redirect.links.filter(it => it != redirectLink) } });
    }

    changeRedirectLink(redirectLink: RedirectLink) {
        const newredirectLinks = this.state.redirect.links.map(it => {
            if (it.id === redirectLink.id) {
                return redirectLink;
            }
            return it;
        });
        this.setState({ redirect: { ...this.state.redirect, links: newredirectLinks } });
    }

    addNewRedirectLink() {
        const minId = Math.min(-1, ...this.state.redirect.links.map(it => it.id));
        const nextId = minId - 1;
        const newredirectLink: RedirectLink = { id: nextId, url: "", active: true, type: defaultTargetRedirectType };
        this.setState({ redirect: { ...this.state.redirect, links: [...this.state.redirect.links, newredirectLink] } });
    }

    renderredirectTitleEditor(redirect: RedirectPolicy) {
        return <Fragment>
            <label className="redirect-editor__name-label" htmlFor="redirectName">Название редиректа</label>
            <input
                id="redirectName"
                type="text"
                className={`text-input redirect-editor__url-input ${!redirect.name.trim().length ? 'text-input_error' : ''}`}
                value={redirect.name}
                placeholder="Название редиректа"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ redirect: { ...this.state.redirect, name: e.target.value } }) }}
            />
            <div></div>
        </Fragment>
    }


    renderRedirectEditor(redirect: RedirectPolicy) {
        return <Fragment>
            {this.renderredirectTitleEditor(redirect)}
            <label className="redirect-editor__name-label" htmlFor="redirectSource">Адрес источника</label>
            <input
                className={`text-input redirect-editor__referer-input ${!isValidReferer(redirect.source) ? 'text-input_error' : ''}`}
                type="text"
                id="redirectSource"
                placeholder="Адрес источника"
                value={redirect.source}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ redirect: { ...this.state.redirect, source: e.target.value } }) }} />

            <RedirectLinkEditor redirectLinks={redirect.links}
                onAddNewRedirectLink={this.addNewRedirectLink}
                onChange={this.changeRedirectLink}
                onRemove={this.removeRedirectLink} />
        </Fragment>
    }

    render() {
        if (this.props.loading) {
            return <Loader visible={true} />
        }

        if (this.state.error) {
            return <div>
                <h1 className="nothing-found-caption">
                    {this.state.error}
                </h1>
            </div >
        }

        return <div className="container">
            <Link className="page-title" to="/redirects">Редиректы</Link>
            <div className="redirects-container page-section">
                <Formik enableReinitialize
                    initialValues={this.state.redirect}
                    validationSchema={redirectValidationSchema}
                    onSubmit={(redirect: RedirectPolicy) => this.saveredirect(redirect)}>
                    {() => (
                        <Form id="editChannel">
                            <div className="redirect-editor section-editor ">
                                {this.renderRedirectEditor(this.state.redirect)}
                            </div>
                        </Form>)
                    }
                </Formik>
                <div className="wide-save-cancel-controls">
                    <button
                        className="wide-save-cancel-controls__button"
                        type="submit"
                        form="editredirect"
                        disabled={!isValidRedirect(this.state.redirect)}
                        onClick={() => this.saveredirect(this.state.redirect)}
                    >Сохранить</button>
                    <Link className="wide-save-cancel-controls__button" type="button" to="/redirects">Отмена</Link>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.redirectPolicy?.loading,
        redirects: state.redirectPolicy?.redirects,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
    return {
        fetchredirectList: () =>
            dispatch(fetchRedirectList()),
        saveredirect: (redirect: RedirectPolicy) =>
            dispatch(saveRedirect(redirect))
    }
};

export const EditRedirect = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRedirectImpl));