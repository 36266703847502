import { API_URL, DefaultRequestTimeout } from '../config';
import { ResponseProcessor } from './ResponseProcessor';
import { AuthService } from './AuthService';

export class BaseService {
    baseUrl = API_URL;
    authService = new AuthService();
    responseProcessor = new ResponseProcessor(this.authService);

    async fetch(url, data, method = "POST") {
        let headers = this.authService.getAuthHeader();

        try {
            const response = await fetch(`${this.baseUrl}/${url}`, {
                method: method,
                headers: headers,
                body: data,
                signal: AbortSignal.timeout(DefaultRequestTimeout)
            });

            const result = await this.responseProcessor.processResponse(response);
            return result;
        }
        catch (error) {
            if (error?.code === 20) {
                throw new Error("Операция прервана по таймауту");
            }
            throw new Error(error.message);
        }
    }

    async get(url) {
        let headers = this.authService.getAuthHeader();

        try {
            const response = await fetch(`${this.baseUrl}/${url}`, {
                method: "GET",
                headers: headers,
                signal: AbortSignal.timeout(DefaultRequestTimeout)
            });

            const result = await this.responseProcessor.processResponse(response);
            return result;
        }
        catch (error) {
            if (error?.code === 20) {
                throw new Error("Операция прервана по таймауту");
            }
            throw new Error(error.message);
        }
    }
}