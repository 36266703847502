import { AuthService } from './AuthService';
import { API_URL } from '../config';
import { ResponseProcessor } from './ResponseProcessor';

export class SearchService {

    constructor() {
        this.url = `${API_URL}/search`;
        this._authService = new AuthService();
        this._responseProcessor = new ResponseProcessor(this._authService);
    }

    async findNews(request) {
        let headers = this._authService.getAuthHeader();
        const { pageNumber, newsPerPage, channelId, pattern } = request;
        const resp =
            await fetch(`${this.url}?pageNumber=${pageNumber}&newsPerPage=${newsPerPage}&channelId=${channelId}&pattern=${pattern}`,
                { headers: headers });
        return await this._responseProcessor.processResponse(resp);
    }

    async findByPrefix(prefix, channelId) {
        let headers = this._authService.getAuthHeader();
        const resp = await fetch(`${this.url}/prefix?prefix=${prefix}&channelId=${channelId ?? ""}`,
            { headers: headers });

        return await this._responseProcessor.processResponse(resp);
    }
}