import * as actionTypes from '../types';
import {FavoritesService} from '../../services/FavoritesService';
import {showErrorAction} from '../Error/ErrorAction';
import {AuthService} from '../../services/AuthService';
import {clearNewList, newsLoaded, newsReloaded, showLoader} from '../News/NewsActions';

const favoritesService = new FavoritesService();

const favoriteIdsFetched = (ids) => {
    return {
        type: actionTypes.FAVORITES_LOADED,
        payload: ids
    };
};

const favoriteIdAdded = (id) => {
    return {
        type: actionTypes.FAVORITES_ADD,
        payload: id
    };
};

const favoriteIdRemoved = (id) => {
    return {
        type: actionTypes.FAVORITES_REMOVE,
        payload: id
    };
};

const fetchFavoriteNews = (request) => {
    return async (dispatch) => {
        const authService = new AuthService();
        if (!authService.isAuthenticated())
            window.location = '/login';

        if (request.pageNumber === 0)
            dispatch(clearNewList());

        dispatch(showLoader(true));

        try {
            const favoriteNews = await favoritesService.getFavoriteNews();
            if (request.pageNumber === 0)
                dispatch(newsReloaded(favoriteNews));
            else
                dispatch(newsLoaded(favoriteNews));
        } catch (error) {
            dispatch(showLoader(false));
            dispatch(showErrorAction(error));
        }
    };
};

const fetchFavoriteIds = (request) => {
    return async (dispatch) => {
        const authService = new AuthService();
        if (!authService.isAuthenticated())
            window.location = '/login';

        try {
            const favoriteIds = await favoritesService.getFavoriteNews(request);
            dispatch(favoriteIdsFetched(favoriteIds));
        } catch (error) {
            console.log("can`t fetch favorites");
        }
    };
};

const addToFavorites = (id) => {
    return async (dispatch) => {
        const authService = new AuthService();
        if (!authService.isAuthenticated())
            window.location = '/login';

        try {
            await favoritesService.addFavorites(id);
            dispatch(favoriteIdAdded(id));
        } catch (error) {
            dispatch(showErrorAction('Не удалось добавить новость в избранное'));
        }
    };
};

const removeFromFavorites = (request) => {
    return async (dispatch) => {
        const authService = new AuthService();
        if (!authService.isAuthenticated())
            window.location = '/login';

        try {
            await favoritesService.removeFromFavorites(request.id);
            dispatch(favoriteIdRemoved(request.id));
            if (request.path === '/favorites') {
                dispatch(fetchFavoriteNews({pageNumber: 0}));
            }
        } catch (error) {
            dispatch(showErrorAction('Не удалось удалить новость из избранного'));
        }
    };
};

export {
    fetchFavoriteNews,
    fetchFavoriteIds,
    favoriteIdsFetched,
    favoriteIdAdded,
    favoriteIdRemoved,
    addToFavorites,
    removeFromFavorites
};