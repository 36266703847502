import * as actionTypes from '../types';

export const channelLoaded = (channelList) => {
    return {
        type: actionTypes.CHANNEL_LOADED,
        payload: channelList,
    };
};


export const channelChange = () => {
    return {
        type: actionTypes.ChannelAction.CHANNEL_CHANGE
    }
}

export const channelChangeSuccess = () => {
    return {
        type: actionTypes.ChannelAction.CHANNEL_CHANGE_SUCCESS
    }
}

export const channelChangeFailed = () => {
    return {
        type: actionTypes.ChannelAction.CHANNEL_CHANGE_FAILED
    }
}
