import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    clearNewList,
    newsChannelChanged,
    newsItemLoaded,
    newsItemSelected,
    newsLoaded
} from '../../actions/News/NewsActions';
import { sliderChannelSelected } from '../../actions/Channels/SliderChannelActions';
import { NewsPageContainer } from '../containers/NewsPageContainer';

export class ChannelNewsPageImpl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channelId: undefined
        };
    }

    componentDidMount() {
        if (this.props.channelId) {
            this.setState({ channelId: this.props.channelId });
            this.props.changeSlider(this.props.channelId);
        }
    }

    componentDidUpdate(nextProps) {
        if (this.props.channelId !== nextProps.channelId) {
            this.setState({ channelId: nextProps.channelId })
            this.props.changeSlider(nextProps.channelId);
        }
    }

    render() {
        return (<NewsPageContainer {...this.props} channelId={this.props.channelId} />);
    }
}


const mapStateToProps = (state) => {
    return {
        selectedChannelId: state.news.selectedChannelId,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadNews: (newsList) => {
            dispatch(newsLoaded(newsList));
        },
        loadNewsItem: (newsItem) => {
            dispatch(newsItemLoaded(newsItem));
        },
        onNewsItemSelected: (index) => {
            dispatch(newsItemSelected(index));
        },
        newsChannelChanged: (channelId) => {
            dispatch(newsChannelChanged(channelId));
            dispatch(sliderChannelSelected(channelId));
        },
        changeSlider: (channelId) => {
            dispatch(sliderChannelSelected(channelId));
        },
        clearNewsList: () => {
            dispatch(clearNewList());
        },
    };
};

export const ChannelNewsPage = connect(mapStateToProps, mapDispatchToProps)(ChannelNewsPageImpl);

