import React, { Component } from "react";
import { connect } from 'react-redux';
import { SocialLinks } from './Social/SocialLinks';
import { addToFavorites, removeFromFavorites } from '../../actions/Favorites/FavoritesAction';

class NewsItemModalControlsImpl extends Component {

    renderFavoritesButton = () => {
        const { favoriteIds, news, path } = this.props;
        if (favoriteIds && favoriteIds.includes(news.id)) {
            return (<button
                className="modal-controls__button"
                onClick={() => {
                    this.props.removeFromFavorites({ id: news.id, path: path });
                }
                }
            >Удалить из избранного</button>);
        }

        return (<button
            className="modal-controls__button"
            onClick={() => this.props.addToFavorites(news.id)}
        >Читать позже</button>);
    }

    render() {
        const { news } = this.props;
        return (
            <ul className="modal-controls">
                <li className="modal-controls__item">
                    <a
                        href={`/redirect/${news.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="modalItemLink"
                        className="modal-controls__button"
                        aria-label="Read source">Смотреть источник
                    </a>
                </li>
                <li className="modal-controls__item">
                    {this.renderFavoritesButton()}
                </li>
                <li className="modal-controls__item modal-controls__item_no-left-border modal-controls__item_social">
                    {<SocialLinks />}
                </li>
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        favoriteIds: state.favorites.newsIds
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToFavorites: (id) => {
            dispatch(addToFavorites(id));
        },
        removeFromFavorites: (id) => {
            dispatch(removeFromFavorites(id))
        }
    }
};

export const NewsItemModalControls = connect(mapStateToProps, mapDispatchToProps)(NewsItemModalControlsImpl);