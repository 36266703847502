import { useDispatch } from "react-redux";
import { showChannelModalAction } from "../../../actions/Modal/ModalActions";

import "./Subscription.css";

export const Subscription = () => {
    const dispatch = useDispatch();

    const onSubscriptionClicked = () => {
        dispatch(showChannelModalAction(true));
    }

    return (
        <button className="header__subscription-button" onClick={onSubscriptionClicked}>
            Мои подписки</button>
    );
}