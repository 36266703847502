import { useDispatch, useSelector } from 'react-redux';
import { showChannelModalAction } from '../../../actions/Modal/ModalActions';
import { cancelSubscrition, saveSubscription } from '../../../actions/Channels';

import './ChannelSubscription.css';

const channelModalAddress = '/channels';

export const ChannelSubscription = () => {
    const originalAddress = '';

    const dispatch = useDispatch();
    const changedChannels = useSelector((state: any) => state.subscriber.changedChannels);

    const doSubscribe = () => {
        dispatch(saveSubscription(changedChannels));
        closeChannelModal();
    }

    const cancelSubscribe = () => {
        dispatch(cancelSubscrition());
        closeChannelModal();
    }

    const closeChannelModal = () => {
        dispatch(showChannelModalAction(false));
        if (!originalAddress || originalAddress === channelModalAddress)
            window.history.pushState({}, '', '');

        else if (window.location.pathname !== originalAddress)
            window.history.pushState({}, '', originalAddress);
    }

    return (<div className="channel-buttons">
        <button
            className="channel-buttons__button"
            onClick={doSubscribe}
        >Сохранить
        </button>
        <button
            className="channel-buttons__button"
            onClick={cancelSubscribe}>Отмена
        </button>
    </div>
    );
}