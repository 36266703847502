import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import './RegistrationBanner.css';
import { AuthService } from '../../services/AuthService';
import { showLoginModalAction, showRegistrationModalAction } from '../../actions/Modal/ModalActions';

export const RegistrationBanner = () => {

    const authService = new AuthService();
    const [isClosed, setIsClosed] = useState(false);
    const dispatch = useDispatch();

    const close = () => {
        setIsClosed(true);
        authService.registrationShown();
    }

    const onRegister = () => {
        dispatch(showLoginModalAction(false));
        dispatch(showRegistrationModalAction(true));
    }

    if (isClosed) {
        return null;
    }
    if (!authService.isFirstVisit()) {
        return null;
    }

    return (<div className="registration-banner">
        <button className="registration-banner__close close" onClick={close} aria-label="Close"></button>
        <h1 className="registration-banner__title"><span
            className="registration-banner__logo">Digezt</span> показывает последние новости выбранных вами
            источников</h1>
        <p className="registration-banner__description">Соберите на одной странице интересные вам
            он-лайн издания, блоги и подкасты.</p>
        <Link to="/registration"
            className="registration-banner__register-link"
            onClick={() => onRegister()}>Регистрация</Link>
    </div>);
}