import {Component} from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {
    showChannelModalAction,
    showLoginModalAction,
    showRegistrationModalAction,
    showResetPasswordModalAction
} from "../../actions/Modal/ModalActions";
import {sliderChannelSelected} from "../../actions/Channels/SliderChannelActions";
import {AuthService} from "../../services/AuthService";
import {
    fetchNews,
    fetchNewsItem,
    newsChannelChanged,
    newsItemSelected,
    showLoader
} from "../../actions/News/NewsActions";
import {NewsPerPage} from "../../config";
import {NewsPage} from "../pages/NewsPage";

interface Props extends RouteComponentProps {
    channelId?: number;
    authService: AuthService,
    loadItems?: any,
    newsId?: number;
}

type TNewsPageContainer = Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class NewsPageContainerImpl extends Component<TNewsPageContainer> {

    componentDidMount() {
        if (this.isChannelRequest())
            this.props.showChannelModal();

        if (this.props.channelId !== this.props.selectedChannelId)
            this.props.newsChannelChanged(this.props.channelId!);

        if (!this.props.channelId)
            this.props.changeSlider(-1);

        if (this.props.loadItems)
            this.loadItems = this.props.loadItems;

        this.loadItems(0);
    }

    isChannelRequest() {
        return this.props.location
            && this.props.location.pathname === "/channels";
    }

    componentDidUpdate() {
        const {channelId, selectedChannelId} = this.props;
        if (channelId !== selectedChannelId) {
            this.loadItems(0);
            this.props.newsChannelChanged(this.props.channelId!);
        }

        const {newsId, newsWasShown, newsList} = this.props;
        if (newsId && newsList.length > 0 && !newsWasShown) {
            const index = newsList.findIndex((n: any) => n.id === newsId);
            if (index === -1)
                this.props.fetchNewsItem(newsId);
            else
                this.props.onNewsItemSelected(index);
        }
    }

    loadItems = (pageNumber: number) => {
        const {channelId} = this.props;
        this.props.fetchNews(pageNumber, NewsPerPage, channelId!);
    };

    showError = () => {
    };

    onNewsItemSelected = (index: number) => {
        this.props.onNewsItemSelected(index);
    };

    onNextPage = () => {
        this.loadItems(this.props.pageNumber);
    };

    onOpenModal = (selectedNewsItem: any) => {
        this.props.onNewsItemSelected(selectedNewsItem);
    };

    render() {
        return (
            <NewsPage
                {...this.props}
                openModal={this.onOpenModal}
                onNextPage={this.onNextPage}
            />
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        newsList: state.news.newsList,
        pageNumber: state.news.pageNumber,
        selectedChannelId: state.news.selectedChannelId,
        loading: state.news.loading,
        selectedNewsItemIndex: state.news.selectedNewsItemIndex,
        newsWasShown: state.news.newsWasShown,
        show: state.news.showModal,
        viewMode: state.viewSelector.viewMode
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showLoginModal: (show: boolean) =>
            dispatch(showLoginModalAction(show)),
        showReigstrationModal: (show: boolean) =>
            dispatch(showRegistrationModalAction(show)),
        showResetPasswordModal: (show: boolean) =>
            dispatch(showResetPasswordModalAction(show)),
        onNewsItemSelected: (index: number) =>
            dispatch(newsItemSelected(index)),
        newsChannelChanged: (channelId: number) =>
            dispatch(newsChannelChanged(channelId)),
        showLoader: () =>
            dispatch(showLoader()),
        changeSlider: (channelId: number) =>
            dispatch(sliderChannelSelected(channelId)),
        fetchNews: (pageNumber: number, newsPerPage: number, channelId: number) =>
            dispatch(fetchNews(pageNumber, newsPerPage, channelId)),
        fetchNewsItem: (id: number) => {
            dispatch(fetchNewsItem(id));
        },
        showChannelModal: () => {
            dispatch(showChannelModalAction(true));
        }
    };
};

export const NewsPageContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewsPageContainerImpl)
);
