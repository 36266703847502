import React, {Component} from 'react';
import {Login} from '../Auth/Login';

import './LoginPage.css';


export class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(props) {
        this.props.signinUser(props);
    }

    render() {
        return (
            <div className="login-container">
                <Login {...this.props} />
            </div>)
    }
}
