import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Formik } from "formik";
import { SocialLogins } from '../Login/SocialLogins';
import { showLoginModalAction, showRegistrationModalAction } from '../../../actions/Modal/ModalActions';
import * as Yup from "yup";
import { TextInput } from '../../Controls';

import './Registration.css';
import { signupUser } from '../../../actions/Auth/Auth';

interface Props {
    onLink: () => void;
}

const validationSchema = Yup.object({
    email: Yup.string().transform(e => e.trim()).email("Поле должно содеражать валидный email").required("Это поле не может быть пустым"),
    password: Yup.string().required("Это поле не может быть пустым").min(6, "Поле должно содержать не менее 6 символов"),
    confirm: Yup.string().required("Это поле не может быть пустым").oneOf([Yup.ref("password")], "Пароль и подтверждение должны совпадать")
        .min(6, "Поле должно содержать не менее 6 символов")

});

export const Registration = (props: Props) => {
    props;
    const dispatch = useDispatch();
    const errorMessage = useSelector((state: any) => state.auth.error);
    const inProcess = useSelector((state: any) => state.auth.inProcess);
    const signup = useSelector((state: any) => state.auth.signup);


    const handleFormSubmit = ({ email, password, confirm }: any) => {
        dispatch(signupUser(email.trim(), password, confirm));
    }

    const onLoginClick = () => {
        dispatch(showRegistrationModalAction(false));
        dispatch(showLoginModalAction(true));
    }

    const renderRegistrationButton = () => {
        if (!inProcess) {
            return (<button className="account-modal-block__button" type="submit">Регистрация</button>)
        } else {
            return (<button className="account-modal-block__button" type="submit" disabled>
                Выполняется регистрация</button>)
        }
    }

    if (signup) {
        return (
            <div className="account-modal-block">
                <h1 className="account-modal-block__title">Аккаунт создан</h1>
                <p className="account-modal-block__message">На ваш электронный адерс отправленно письмо с
                    подтверждением, <br />
                    обязательно подтвердите ваш адрес перед входом</p>
                <Link to="/login"
                    className="account-modal-block__button"
                    onClick={onLoginClick}
                >Вход</Link>
            </div>
        )
    }

    return (
        <div className="account-modal-block">
            <h1 className="account-modal-block__title">Создать аккаунт</h1>
            <Formik enableReinitialize
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleFormSubmit(values)}>
                {() => (
                    <Form className="registration-block">
                        <Fragment>
                            {errorMessage && errorMessage.length > 0 && (
                                <span className="modal-block__error modal-block__error_summary">{errorMessage}</span>)}
                            <TextInput className='account-modal-block__input text-input text-input_large'
                                name="email"
                                type="text"
                                placeholder="Введите свой E-mail" />

                            <TextInput className='account-modal-block__input text-input text-input_large'
                                name="password"
                                type="password"
                                placeholder="Введите пароль" />

                            <TextInput className='account-modal-block__input text-input text-input_large'
                                name="confirm"
                                type="password"
                                placeholder="Подтверждение пароля" />
                            {renderRegistrationButton()}
                        </Fragment>
                    </Form>
                )}
            </Formik>
            {<SocialLogins actionName="Зарегистрироваться" />}
        </div>
    )
}

