import * as actionTypes from '../actions/types';

const initialState = {
    showModal: false
};

const channelModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_CHANNEL_MODAL :
            return {
                showModal: action.payload
            };

        default :
            return state;
    }
}

export {channelModalReducer};