import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Modal from 'react-modal';
import './GenericModal.css';

export class GenericModal extends Component {

    modal = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: props.isOpen
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    componentDidUpdate() {
        if (this.state.modalIsOpen !== this.props.isOpen)
            this.setState({ modalIsOpen: this.props.isOpen });
    }

    componentDidMount() {
        this.setState({ modalIsOpen: this.props.showModal });
    }

    stopScroll = (e) => {
        e.preventDefault();
    }

    afterOpenModal = () => {
        document.body.addEventListener('scroll', this.stopScroll);
        if (this.props.onOpen)
            this.props.onOpen();
    }

    closeModal() {
        document.body.removeEventListener('scroll', this.stopScroll);

        this.setState({ modalIsOpen: false });
        if (this.props.onClose)
            this.props.onClose();
    }

    renderArrow(showArrow) {
        if (!showArrow)
            return null;
        return (
            <React.Fragment>
                <button
                    className="modal__move-button modal__move-button_next"
                    onClick={() => this.props.onArrowClick(1)}
                    disabled={this.props.lastItem}></button>
                <button
                    className="modal__move-button modal__move-button_prev"
                    onClick={() => this.props.onArrowClick(-1)}
                    disabled={this.props.firstItem}></button>
            </React.Fragment>
        );
    }

    onOverlayClick = (e) => {
        const { shouldCloseOnOverlayClick } = this.props;
        const domNode = ReactDOM.findDOMNode(this.modal.current);
        if (domNode.contains(e.target)) {
            return;
        }
        if (shouldCloseOnOverlayClick && !e.target.classList.contains('modal__move-button')) {
            this.closeModal();
        }
    }

    render() {
        const { additionalClasses = '' } = this.props;
        return (
            <div onClick={this.onOverlayClick}>
                <Modal className={this.props.className}
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
                    toggle={this.closeModal}>
                    <div className={`modal-dialog ${additionalClasses}`} ref={this.modal}>
                        <button type="button" className="close" aria-label="Close" onClick={this.closeModal}></button>
                        {this.renderArrow(this.props.showArrow)}
                        {this.props.children}
                    </div>
                </Modal>
            </div>)
    }
}