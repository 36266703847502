import React, {Component} from 'react';
import {ErrorIndicator} from '../ErrorIndicator';

export class ErrorBoundary extends Component {

    state = {
        hasError: false
    }

    componentDidCatch() {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError)
            return <ErrorIndicator/>
        else
            return this.props.children;
    }
}