import * as actionTypes from '../types';
import { TSubscriptionAction } from './subscription-action-types';

export const subscriptionLoaded = (): TSubscriptionAction => {
    return {
        type: actionTypes.SubscribtionAction.LOAD_CHANNEL,
        payload: undefined,
    };
};

export const subscriptionLoadedSuccess = (channels: Array<any>): TSubscriptionAction => {
    return {
        type: actionTypes.SubscribtionAction.LOAD_CHANNEL_SUCCESS,
        payload: channels,
    };
};

export const subscriptionLoadedFailed = (error: any): TSubscriptionAction => {
    return {
        type: actionTypes.SubscribtionAction.LOAD_CHANNEL_FAILED,
        payload: error,
    };
};

export const channelPageChanged = (offset: number): TSubscriptionAction => {
    return {
        type: actionTypes.SubscribtionAction.SUBSCRIPTION_PAGE_CHANGED,
        payload: offset
    };
};

export const channelFiterChanged = (filter: any) => {
    return {
        type: actionTypes.SubscribtionAction.CHANNEL_FILTER_CHANGED,
        payload: filter
    };
};

export const channelSubscriptionsChanged = (id: number) => {
    return {
        type: actionTypes.SubscribtionAction.CHANNEL_SUBSCRIPTION_CHANGED,
        payload: id
    };
}

export const subscriptionCancel = () => {
    return {
        type: actionTypes.SubscribtionAction.CANCEL_SUBSCRIPTION
    }
}

export const channelValidating = (inProcess: boolean, error: any) => {
    return {
        type: actionTypes.SubscribtionAction.CHANNEL_VALIDATING,
        payload: { inProcess, error }
    };
}

export const channelAdded = (channel: any) => {
    return {
        type: actionTypes.SubscribtionAction.CHANNEL_ADDED,
        payload: channel
    };
}