import { IChannelFilter } from "./ChannelFilter";

export class ChannelFiterManager {
    isChannelMatchFilter = (channel: any, filter: IChannelFilter) => {
        let matched = false;

        if (filter.status === 'all')
            matched = true;
        else if (filter.status === 'active')
            matched = channel.subscribed;
        else if (filter.status === 'not_active')
            matched = !channel.subscribed;
        if (filter.pattern.length > 0) {
            matched = matched && (channel.name.toLowerCase().includes(filter.pattern.toLowerCase())
                || this.isDescriptionMathFilter(channel.description, filter.pattern)
                || channel.url.toLowerCase().includes(filter.pattern.toLowerCase()));
        }
        return matched;
    }

    isDescriptionMathFilter(description: string, pattern: string) {
        if (!description || description.trim().length === 0)
            return false;

        return description.toLowerCase().includes(pattern.toLowerCase());
    }
}