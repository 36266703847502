import { useDispatch, useSelector } from 'react-redux';
import { GenericModal } from '../GenericModal';
import { hideError } from '../../actions/Error/ErrorAction';

import './ErrorNotificator.css';

export const ErrorNotificator = () => {

    const dispatch = useDispatch();
    const show = useSelector((state: any) => state.error.show);
    const error = useSelector((state: any) => state.error.error);

    const closeModal = () => {
        dispatch(hideError());
        document.body.removeAttribute('style');
        // document.body.removeEventListener('scroll', this.stopScroll);
    }

    return (
        <GenericModal
            isOpen={show}
            onClose={closeModal}>

            <h1 className="error-modal__title">Произошла ошибка</h1>
            <p className="error-modal__body">{error}
            </p>
            <button
                className="modal-controls__button"
                onClick={closeModal}>Закрыть
            </button>
        </GenericModal>
    );
}