import { Link } from "react-router-dom";
import { Feedback } from "../Feedback/Feedback";

import "./Footer.css";

export const Footer = () => {
    return (
        <footer className="content-block footer">
            <nav className="footer__nav">
                <ul className="additional-nav">
                    <li className="additional__item additional__item_logo">
                        <Link className="additional-nav__link additional-nav__link_logo" to="/">Digezt</Link>
                    </li>
                    <li className="additional__item">
                        <Link className="additional-nav__link" to="/about">О проекте</Link>
                    </li>
                    <li className="additional__item">
                        <Feedback />
                    </li>
                    <li className="additional__item">
                        <Link className="additional-nav__link" to="/policy">Пользовательское соглашение</Link>
                    </li>
                    <li className="additional__item additional__item_social">
                        <a className="additional-nav__link additional-nav__link_social additional-nav__link_vk"
                            href="vk.com">vk</a>
                    </li>
                </ul>
            </nav>
        </footer>
    );
}
