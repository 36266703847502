import { Route, Switch } from "react-router";
import { Layout } from "./components/Layout";
import { About } from "./components/About/About";
import { Policy } from "./components/Policy/Policy";
import { NewsPageContainer } from "./components/containers/NewsPageContainer";
import { LoginPage } from "./components/pages/LoginPage";
import { Profile } from "./components/Profile";
import { ChannelPage } from "./components/pages/ChannelPage";
import { ChannelNewsPage } from "./components/pages/ChannelNewsPage";
import { SearchPage } from "./components/pages/SearchPage";
import { AuthService } from "./services/AuthService";
import { Confirmation } from "./components/Auth/Confirmation/Confirmation";
import { FavoritesPage } from "./components/pages/FavoritesPage";
import { ResendConfirmation } from './components/Auth/ResendConfirmation';
import { ResetPasswordConfirmation } from './components/Auth/ResetPasswordConfirmation';
import { useDispatch } from "react-redux";
import {
    showLoginModalAction,
    showRegistrationModalAction,
    showResetPasswordModalAction
} from "./actions/Modal/ModalActions";
import { ResetPasswordWasSent } from "./components/Auth/ResetPasswordConfirmation/ResetPasswordWasSent";
import { NewsItemPage } from "./components/pages/NewsItemPage";
import { RedirectList, EditRedirect } from "./components/Banners";
import { NotFoundPage } from "./components/pages/NotFoundPage";
import { EditChannel } from "./components/Channels/EditChannel";

import "./App.css";

const authService = new AuthService();

const App = () => {
    const dispatch = useDispatch();
    return (
        <Layout>
            <Switch>
                <Route exact path="/"
                    render={() => {
                        return (
                            <NewsPageContainer authService={authService} />
                        );
                    }}
                />
                <Route path="/login"
                    render={() => {
                        dispatch(showLoginModalAction(true));
                        dispatch(showResetPasswordModalAction(false));
                        return (
                            <NewsPageContainer authService={authService} />
                        );
                    }}
                />
                <Route path="/registration"
                    render={() => {
                        dispatch(showLoginModalAction(false));
                        dispatch(showRegistrationModalAction(true));
                        return (
                            <NewsPageContainer authService={authService} />
                        );
                    }}
                />
                <Route path="/reset-password-request"
                    render={() => {
                        dispatch(showLoginModalAction(false));
                        dispatch(showResetPasswordModalAction(true));
                        return (
                            <NewsPageContainer authService={authService} />
                        );
                    }}
                />
                <Route exact path="/channels"
                    render={() => {
                        return (
                            <NewsPageContainer authService={authService} />
                        );
                    }}
                />
                <Route path="/channels/edit/:id"
                    render={({ match }) => {
                        const { id } = match.params;
                        const channelIdValue = Number(id);
                        return (
                            <EditChannel
                                channelId={channelIdValue}
                                authService={authService}
                            />
                        );
                    }}
                />
                <Route path="/channels/:idWithName"
                    render={({ match }) => {
                        const { idWithName } = match.params;
                        const channelId = idWithName.match(/^\d+/)![0];
                        const channelIdValue = Number(channelId);
                        return (
                            <ChannelNewsPage
                                channelId={channelIdValue}
                                authService={authService}
                            />
                        );
                    }}
                />
                <Route path="/item/:idWithSlug"
                    render={({ match }) => {
                        const { idWithSlug } = match.params;
                        const newsId = idWithSlug.match(/^\d+/)![0];
                        const id = parseInt(newsId, 10);
                        return (
                            <NewsPageContainer
                                newsId={id}
                                authService={authService}
                            />
                        );
                    }}
                />
                <Route path="/news/:idWithSlug"
                    render={({ match }) => {
                        match;
                        return (
                            <NewsItemPage />
                        );
                    }}
                />
                <Route path="/search/:pattern/:channelId?"
                    render={({ match }) => {
                        const { pattern, channelId } = match.params;
                        return (
                            <SearchPage
                                pattern={pattern}
                                channelId={channelId ? Number(channelId) : undefined}
                                authService={authService}
                            />
                        );
                    }}
                />
                <Route exact path="/redirects"
                    render={() => {
                        const isAdmin = authService.isAdmin()
                        if (!isAdmin) {
                            return <NotFoundPage />
                        }
                        return (
                            <RedirectList />
                        );
                    }}
                />
                <Route exact path="/redirects/edit/:id?"
                    render={() => {
                        const isAdmin = authService.isAdmin()
                        if (!isAdmin) {
                            return <NotFoundPage />
                        }
                        return (
                            <EditRedirect />
                        );
                    }}
                />
                <Route path="/favorites" render={() => {
                    return (
                        <FavoritesPage authService={authService} />)
                }} />
                <Route path="/about" component={About} />
                <Route path="/policy" component={Policy} />
                <Route path="/signin" component={LoginPage} />
                <Route path="/profile" component={Profile} />
                <Route path="/subscribe" component={ChannelPage} />
                <Route path="/confirmation/:email" component={Confirmation} />
                <Route path="/resend/:email" component={ResendConfirmation} />
                <Route path="/password-reset-sent" component={ResetPasswordWasSent} />
                <Route path="/email-reset-password/:email/:token" component={ResetPasswordConfirmation} />
                <Route component={NotFoundPage} />
            </Switch>
        </Layout>
    );
};

export default App;
