import * as actionTypes from '../types';

export const showLoginModalAction = (show) => {
    return {
        type: actionTypes.SHOW_LOGIN_MODAL,
        payload: show
    };
};

export const hideLoginModalAction = () => {
    return {
        type: actionTypes.HIDE_LOGIN_MODAL
    }
}

export const showChannelModalAction = (show) => {
    return {
        type: actionTypes.SHOW_CHANNEL_MODAL,
        payload: show
    };
};

export const showRegistrationModalAction = (show) => {
    return {
        type: actionTypes.SHOW_REGISTRATION_MODAL,
        payload: show
    };
};

export const showResetPasswordModalAction = (show) => {
    return {
        type: actionTypes.SHOW_RESET_PASSWORD_MODAL,
        payload: show
    };
};
