import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {confirmEmail, resendVerification} from '../../../actions/Auth/Auth';

import './ResendConfirmation.css';

class ResendConfirmationImpl extends Component {

    resendVerification = () => {
        const email = this.props.match.params.email;
        this.props.resendVerification(email);
    }

    render() {
        const {inProcess, emailSent} = this.props;
        if (inProcess) {
            return (
                <p className="confirmation__message"> Высылаем пиьсмо для подверждения на ваш адрес электронной почты
                    это может занять какое-то время</p>);
        }
        if (emailSent) {
            return (
                <div className="confirmation__block">
                    <p className="confirmation__message">На ваш электронный адерс отправленно письмо с
                        подтверждением</p>
                    <Link to="/" className="confirmation__link">На главную</Link>
                    <Link to="/login" className="confirmation__link">Войти</Link>
                </div>);
        }

        return (<React.Fragment>
            <p className="confirmation__message">Ваш Email неподтвержден </p>
            <button className="confirmation__button"
                    onClick={() => this.resendVerification()}
            >Выслать подтверждение еще раз
            </button>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => {
    return {
        inProcess: state.auth.confirmationInProcess,
        error: state.auth.error,
        emailSent: state.auth.emailSent
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        confirmEmail: (email, token) => {
            dispatch(confirmEmail(email, token));
        },
        resendVerification: (email) => {
            dispatch(resendVerification(email));
        }
    };
};

export const ResendConfirmation = (connect(mapStateToProps, mapDispatchToProps)(ResendConfirmationImpl));