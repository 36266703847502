import * as actionTypes from '../types';
import {showErrorAction} from '../Error/ErrorAction';
import {ProfileService} from '../../services/ProfileService';
import {AuthService} from '../../services/AuthService';

const profileService = new ProfileService();
const authService = new AuthService();

const userProfileFetch = () => {
    return {
        type: actionTypes.USER_PROFILE_FETCH
    };
};

const userProfileFetchSuccess = (profile) => {
    return {
        type: actionTypes.USER_PROFILE_FETCH_SUCCESS,
        payload: profile
    };
};

const userProfileFetchFailed = (error) => {
    return {
        type: actionTypes.USER_PROFILE_FETCH_FAILED,
        payload: error
    };
};

const emailChanging = () => {
    return {
        type: actionTypes.USER_PROFILE_EMAIL_CHANGE
    };
};

const emailChanginSuccess = () => {
    return {
        type: actionTypes.USER_PROFILE_EMAIL_CHANGE_SUCCESS
    }
}

const passwordChangedSuccess = () => {
    return {
        type: actionTypes.USER_PROFILE_PASSWORD_CHANGED_SUCCESS,

    };
};

const fetchUserProfile = () => {
    return async (dispatch) => {
        try {
            const userProfile = await profileService
                .getUserProfile();

            dispatch(userProfileFetchSuccess(userProfile));
        } catch (error) {
            dispatch(showErrorAction(error));
        }
    };
};

const changeUserPassword = (oldPassword, newPassword, success) => {
    return async (dispatch) => {
        try {
            const status = await authService
                .changeUserPassword(oldPassword, newPassword);

            if (status === 200) {
                success();
                dispatch(passwordChangedSuccess());
            } else
                dispatch(showErrorAction('Произошла ошибка при изменении пароля. Возможно Вы указали неверный старый пароль'));
        } catch (error) {
            dispatch(showErrorAction(error));
        }
    };
};

const changeSocialPageAddress = (socialName, pageAddress) => {
    return async (dispatch) => {
        try {
            const userProfile = await profileService
                .changeSocialPage(socialName, pageAddress);

            dispatch(userProfileFetchSuccess(userProfile));
        } catch (error) {
            dispatch(showErrorAction(error));
        }
    };
};

const changeEmail = (email) => {
    return async (dispatch) => {
        try {
            dispatch(emailChanging());
            await authService
                .changeEmail(email);

            authService.logout();
            dispatch(emailChanginSuccess());
        } catch (error) {
            dispatch(showErrorAction(error));
        }
    };
}

export {
    userProfileFetch,
    userProfileFetchSuccess,
    userProfileFetchFailed,
    fetchUserProfile,
    changeSocialPageAddress,
    changeUserPassword,
    changeEmail,
    emailChanging,
    emailChanginSuccess
};