import * as actionTypes from '../actions/types';

const initialState = {
    showModal: false
};

const loginModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_LOGIN_MODAL:
            return {
                showModal: action.payload
            };
        case actionTypes.HIDE_LOGIN_MODAL:
            return {
                showModal: false
            }

        default:
            return state;
    }
}

export { loginModalReducer };