import React, {Component} from 'react';
import {GenericModal} from '../../GenericModal/GenericModal';
import {connect} from 'react-redux';
import {showResetPasswordModalAction} from '../../../actions/Modal/ModalActions';
import {ResetPassword} from "../ResetPassword";

import './ResetPasswordModal.css';

class ResetPasswordModalImpl extends Component {

    closeModal = () => {
        this.props.showModal(false);
        document.body.removeAttribute('style');
        document.body.removeEventListener('scroll', this.stopScroll);
        window.history.replaceState({}, {}, '/');
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <GenericModal
                className="account-modal"
                isOpen={this.props.show}
                onClose={this.closeModal}>
                <ResetPassword/>
            </GenericModal>
        );
    }
}

const mapStateToProps = (state) => {
    return {show: state.resetPass.showModal};
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (show) => {
            dispatch(showResetPasswordModalAction(show));
        }
    }
};

export const ResetPasswordModal = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModalImpl);
