import { Reducer } from "redux";
import { BaseReducer } from "./BaseReducer";
import * as actionTypes from '../actions/types';
import { TSubscriptionAction } from "../actions/Channels/subscription-action-types";
import { IChannelFilter } from "../domain/subscriptions/ChannelFilter";

interface ISubscritpionsState {
    loading: boolean,
    channels: Array<any>,
    filter: IChannelFilter;
    offset: number,
    validating: boolean,
    changedChannels: Array<any>,
    error: any,

}

export class InitialState implements ISubscritpionsState {
    loading: boolean = false;
    channels = [];
    offset = 0;
    validating = false;
    changedChannels = [];
    filter = {
        status: "all",
        type: "all",
        pattern: ''
    }
    error: any = null;
}

const handlers = {
    [actionTypes.SubscribtionAction.LOAD_CHANNEL]: channelLoad,
    [actionTypes.SubscribtionAction.LOAD_CHANNEL_SUCCESS]: channelLoadSuccess,
    [actionTypes.SubscribtionAction.LOAD_CHANNEL_FAILED]: channelLoadFailed,
    [actionTypes.SubscribtionAction.SUBSCRIPTION_PAGE_CHANGED]: subscriptionPageChanged,
    [actionTypes.SubscribtionAction.CHANNEL_FILTER_CHANGED]: channelFilterChanged,
    [actionTypes.SubscribtionAction.CHANNEL_SUBSCRIPTION_CHANGED]: channelSubscriptionChanged,
    [actionTypes.SubscribtionAction.CANCEL_SUBSCRIPTION]: cancelSubscriptionChanges,
    [actionTypes.SubscribtionAction.CHANNEL_VALIDATING]: channelValidating,
    [actionTypes.SubscribtionAction.CHANNEL_ADDED]: channelAdded,
};

function channelLoad(state: ISubscritpionsState) {
    return {
        ...state,
        loading: true,
        channels: [],
        changedChannels: [],
        filter: {
            status: "all",
            type: "all",
            pattern: ''
        },
        error: null
    }
}

function channelLoadSuccess(state: ISubscritpionsState, action: TSubscriptionAction) {
    return {
        ...state,
        loading: false,
        channels: action.payload,
    }
}

function channelLoadFailed(state: ISubscritpionsState, action: TSubscriptionAction) {
    return {
        ...state,
        loading: false,
        error: action.payload
    }
}

function subscriptionPageChanged(state: ISubscritpionsState, action: TSubscriptionAction) {
    return {
        ...state,
        offset: action.payload
    }
}

function channelFilterChanged(state: ISubscritpionsState, action: TSubscriptionAction) {
    return {
        ...state,
        filter: action.payload,
        offset: 0,
        error: null
    }
}

function cancelSubscriptionChanges(state: ISubscritpionsState) {
    return {
        ...state,
        changedChannels: []
    }
}

function channelValidating(state: ISubscritpionsState, action: TSubscriptionAction) {
    return {
        ...state,
        loading: action.payload.inProcess,
        validating: action.payload.inProcess,
        error: action.payload.error
    };
}

function channelAdded(state: ISubscritpionsState, action: TSubscriptionAction) {
    return {
        ...state,
        validating: false,
        loading: false,
        error: null,
        filter: { ...state.filter, status: "all" },
        channels: [action.payload, ...state.channels]
    };
}

function channelSubscriptionChanged(state: ISubscritpionsState, action: TSubscriptionAction) {
    const id = action.payload;
    const index = state.channels.findIndex(it => it.id === id);
    const channel = state.channels[index];
    const changedChannel = Object.assign({}, { ...channel }, { subscribed: !channel.subscribed });
    const newChannels = [...state.channels.slice(0, index),
        changedChannel, ...state.channels.slice(index + 1)];

    let changedChannels = [];
    const changedIndex = state.changedChannels.findIndex(it => it.id === id);
    if (changedIndex === -1)
        changedChannels = [...state.changedChannels,
        { id: id, currentSubsctiption: !channel.subscribed, originalSubscribtion: channel.subscribed }];
    else {
        changedChannels = [...state.changedChannels.slice(0, changedIndex),
        Object.assign({}, { ...state.changedChannels[changedIndex] }, { currentSubsctiption: changedChannel.subscribed }),
        ...state.changedChannels.slice(changedIndex + 1)]
    }

    return {
        ...state,
        channels: newChannels,
        changedChannels: changedChannels
    };
}


export const subscriberReducer: Reducer<ISubscritpionsState, TSubscriptionAction> = (state: ISubscritpionsState | undefined = new InitialState(), action: TSubscriptionAction) => {
    const baseReducer = new BaseReducer(handlers);
    const handler: any = baseReducer.getReducer(action.type)
    return handler ? handler(state, action) : state;
}