import { authReducer } from './AuthReducer';
import { resetPasswordReducer } from './ResetPasswordReducer';
import { userReducer } from './UserReducer';
import { loginModalReducer } from './LoginModalReducer';
import { channelReducer } from './ChannelReducer';
import { newsItemReducer } from './NewsReducer';
import { viewSelectorReducer } from './ViewSelectorReducer';
import { channelSliderReducer } from './ChannelSliderReducer';
import { channelModalReducer } from './ChannelModalReducer';
import { errorReducer } from './ErrorReducer';
import { registrationModalReducer } from './RegistrationModalReducer';
import { favoritesReducer } from './FavoritesReducer';
import { profileReducer } from './ProfileReducer';
import { feedbackReducer } from './FeedbackReducer';
import { redirectPolicyReducer } from "./RedirectReducer";
import { subscriberReducer } from "./SubscriberReducer";


export const reducers = {
    auth: authReducer,
    resetPass: resetPasswordReducer,
    user: userReducer,
    loginModal: loginModalReducer,
    registrationModal: registrationModalReducer,
    channel: channelReducer,
    subscriber: subscriberReducer,
    news: newsItemReducer,
    favorites: favoritesReducer,
    viewSelector: viewSelectorReducer,
    channelSlider: channelSliderReducer,
    channelModal: channelModalReducer,
    userProfile: profileReducer,
    feedback: feedbackReducer,
    error: errorReducer,
    redirectPolicy: redirectPolicyReducer
};
