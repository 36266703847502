import { useDispatch, useSelector } from 'react-redux';
import { changeFilter } from '../../../actions/Channels';

import './ChannelFilter.css';

export const ChannelFilter = () => {

    const dispatch = useDispatch();
    const channelStatus = useSelector((state: any) => state.subscriber.filter.status);
    const channelType = useSelector((state: any) => state.subscriber.filter.type);
    const pattern = useSelector((state: any) => state.subscriber.filter.pattern);

    const onStatusChange = (status: string) => {
        if (channelStatus !== status) {
            dispatch(changeFilter({ status, type: channelType, pattern: pattern }));
        }
    }

    return (
        <div className="channel-filter-wrapper">
            <ul className="channel-filter channel-filter_top">
                <li className="channel-filter__item channel-filter__item_caption">
                    <span className="channel-filter__caption">Показать :</span>
                </li>
                <li className="channel-filter__item">
                    <button
                        className={`channel-filter__buton ${channelStatus === 'active' ? ' channel-filter__buton_active' : ''}`}
                        onClick={() => onStatusChange('active')}>
                        Активные источники
                    </button>
                </li>
                <li className="channel-filter__item">
                    <button
                        className={`channel-filter__buton ${channelStatus === 'not_active' ? ' channel-filter__buton_active' : ''}`}
                        onClick={() => onStatusChange('not_active')} >
                        Неактивные источники
                    </button>
                </li>
                <li className="channel-filter__item">
                    <button
                        className={`channel-filter__buton ${channelStatus === 'all' ? ' channel-filter__buton_active' : ''}`}
                        onClick={() => onStatusChange('all')}>
                        Все
                    </button>
                </li>
            </ul>
        </div>
    );
}